<template>
<!-- For desktop view -->
    <div v-if="!mobile()" class="project-wrapper" style="margin-top: 116px !important">
        <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
        <a-row class="row-space-56">
            <a-upload
    name="avatar"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :before-upload="beforeUpload"
    @change="handleChange"
  >
            <a-col class="profile-photo" :span="4" style="width: 74px;">
                <div class="imageInn">
                    <img style="border-radius: 50%; height: 74px; width: 74px;" @error="imageError()" :src="profilePhotoURL" />
                </div>
                <div class="hoverImg">
                    <img style="height: 114px; width: 114px; margin-left:-20px; margin-top: -16px;" src="../../assets/profileimageoverlay.svg" />
                </div>
            </a-col>
    </a-upload>
            <a-col style="position:relative; margin-top: -85px; margin-left:100px;">
                <div class="header-2">My Account</div>
                <div class="paragraph-large row-space-8">Edit your personal details or reset your password.</div>
            </a-col>
        </a-row>
        <a-row :gutter="40" class="row-space-48">
            <a-col :span="12">
                <a-row class="header-4">Personal Details</a-row>
                <a-form :form="form" @submit="handleSubmit">
                <a-row class="row-space-16 table-style" style="height:460px;padding: 47px 73px;">
                    <div><a-form-item> <!-- DisplayName input -->
                        <a-row :gutter="16">
                            <a-col :span="24">
                                <div class="button-regular" style="color: #223273;">Display Name</div>
                                <div class="row-space-16">
                                    <a-input style="height: 46px;"
                                        v-decorator="['displayName', { rules: [{ required: true, message: 'Please input a valid display name' }],  initialValue:displayName }]"/>
                                </div>
                            </a-col>
                        </a-row></a-form-item>
                        <a-row :gutter="16" class="row-space-32">
                            <a-col v-if="dashmoteUser" :span="24">
                                <div class="button-regular" style="color: #223273;">Company</div>
                                <a-tooltip title="Cannot be changed for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                                <div class="row-space-16">
                                    <a-input disabled placeholder="Dashmote" style="height: 46px;"></a-input>
                                </div>
                                </a-tooltip>
                            </a-col> <!-- EDIT Company for non Dashmote user -->
                            <a-col v-else :span="24">
                                <div class="button-regular" style="color: #223273;">Company</div>
                                <div class="row-space-16">
                                    <a-form-item style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['company', { rules: [{ required: false, message: 'Please input a valid company name' }],  initialValue:company }]"/></a-form-item>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-space-32">
                            <div class="button-regular" style="color: #223273;">Email address</div>
                            <a-tooltip v-if="dashmoteUser" title="Cannot be changed for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                            <div class="row-space-16">
                                <a-input disabled :placeholder="email" style="height: 46px;"></a-input>
                            </div>
                            </a-tooltip>
                            <a-tooltip v-else title="Cannot be changed right now"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                            <div class="row-space-16">
                                <a-input disabled :placeholder="email" style="height: 46px;"></a-input>
                            </div>
                            </a-tooltip>
                        </a-row><a-form-item> <!-- Update details submit button -->
                        <a-row style="text-align: center;" class="row-space-48">
                            <a-button class="primary-button-style-total" type="primary" html-type="submit">Update Details</a-button>
                        </a-row></a-form-item>
                    </div>
                </a-row>
                </a-form>
            </a-col>
            <a-col :span="12">
               <a-row class="header-4">Password</a-row>
               <a-form :form="form" @submit="handleUpdatePassword">
                <a-row class="row-space-16 table-style" style="height:460px;padding: 47px 73px;">
                    <a-row>
                        <div class="button-regular" style="color: #223273;">Current password</div>
                        <a-tooltip v-if="dashmoteUser" title="Cannot be changed for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                                <a-input disabled placeholder="Enter your current password" style="height: 46px;"></a-input>
                        </div>
                        </a-tooltip>
                        <a-tooltip v-else title=""> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                                <a-form-item style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['currentPassword', { rules: [{required: false, message: 'Enter your current password' }] }]" type="password"/></a-form-item>
                        </div>
                        </a-tooltip>
                    </a-row>
                    <a-row class="row-space-32">
                        <div class="button-regular" style="color: #223273;">New password</div>
                        <a-tooltip v-if="dashmoteUser" title="Cannot be changed for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                            <a-input disabled placeholder="Enter your new password" style="height: 46px;"></a-input>
                        </div>
                        </a-tooltip>
                        <a-tooltip v-else title=""> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                                <a-form-item style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['newPassword', { rules: [{required: false, message: 'Enter your new password' }] }]" type="password"/></a-form-item>
                        </div>
                        </a-tooltip>
                    </a-row>
                    <a-row class="row-space-32">
                        <div class="button-regular" style="color: #223273;">Confirm password</div>
                        <a-tooltip v-if="dashmoteUser" title="Cannot be changed for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                            <a-input disabled placeholder="Confirm your new password" style="height: 46px;"></a-input>
                        </div>
                        </a-tooltip>
                        <a-tooltip v-else title=""> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                                <a-form-item style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['confirmPassword', { rules: [{required: false, message: 'Confirm your new password' }] }]" type="password"/></a-form-item>
                        </div>
                        </a-tooltip>
                    </a-row><a-form-item>
                    <a-row class="row-space-48">
                        <a-tooltip title="Forgot Password is not available for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <a-col :span="12">
                            <a disabled @click="forgotPassword" class="forgot-pwd-style"></a> <!-- No option for forgotPassword now -->
                        </a-col>
                        </a-tooltip>
                        <a-col v-if="dashmoteUser" :span="12">
                            <a-button disabled class="primary-button-style-total" html-type="submit" style="float: right;">Update Password</a-button>
                        </a-col>
                        <a-col v-else :span="12">
                            <a-button class="primary-button-style-total" html-type="submit" style="float: right;">Update Password</a-button>
                        </a-col>
                    </a-row></a-form-item>
                </a-row>
               </a-form>
            </a-col>
        </a-row>
    </div>

<!-- For mobile view -->
    <div v-else class="project-wrapper">
        <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
        <a-row class="row-space-56">
            <a-col :span="4" style="width: 75px;"><img style="border-radius: 50%; height: 64px; width: 64px;" @error="imageError()" :src="profilePhotoURL" /></a-col>
            <a-col>
                <div class="header-1" style="color:#223273">My Account</div>
                <div class="paragraph-large-mobile row-space-8">Edit your personal details or reset your password.</div>
            </a-col>
        </a-row>

        <a-tabs type="card" style="margin-top: 15px;">
        <a-tab-pane key="1" tab="Profile">
          <a-row :gutter="40" class="row-space-8">
            <a-col :span="24">
              <a-form :form="form" @submit="handleSubmit">
                <a-row class="row-space-16" style="height:460px;padding: 0px 0px;">
                    <div>
                        <a-row :gutter="16">
                            <a-col :span="24">
                                <div class="button-regular" style="color: #223273;">Display Name</div>
                                <div class="row-space-16">
                                    <a-form-item style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['displayName', { rules: [{ required: true, message: 'Please input a valid display name' }],  initialValue:displayName }]"/></a-form-item>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16" class="row-space-32">
                            <a-col :span="24">
                                <div class="button-regular" style="color: #223273;">Company</div>
                                <div class="row-space-16">
                                  <a-input v-if="dashmoteUser" disabled placeholder="Dashmote" style="height: 46px;"></a-input>
                                  <a-form-item v-else style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['company', { rules: [{ required: false, message: 'Please input a valid company name' }],  initialValue:company }]"/></a-form-item>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-space-32">
                            <div class="button-regular" style="color: #223273;">Email address</div>
                            <div class="row-space-16">
                                <a-input disabled placeholder="email" style="height: 46px;"></a-input>
                            </div>
                        </a-row>
                        <a-form-item> <!-- Update details submit button -->
                        <a-row style="text-align: center;" class="row-space-48">
                            <a-button class="primary-button-style-total" type="primary" html-type="submit">Update Details</a-button>
                        </a-row></a-form-item>
                    </div>
                </a-row>
              </a-form>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Password">
          <a-form :form="form" @submit="handleUpdatePassword">
          <a-row class="row-space-16" style="height:460px;padding: 5px 0px;">
                    <a-row>
                        <div class="button-regular" style="color: #223273;">Current password</div>
                        <div class="row-space-16">
                            <a-input v-if="dashmoteUser" disabled placeholder="Current password" style="height: 46px;"></a-input>
                            <a-form-item v-else style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['currentPassword', { rules: [{required: false, message: 'Enter your current password' }] }]" type="password"/></a-form-item>
                        </div>
                    </a-row>
                    <a-row class="row-space-32">
                        <div class="button-regular" style="color: #223273;">New password</div>
                        <div class="row-space-16">
                            <a-input v-if="dashmoteUser" disabled placeholder="New password" style="height: 46px;"></a-input>
                            <a-form-item v-else style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['newPassword', { rules: [{required: false, message: 'Enter your new password' }] }]" type="password"/></a-form-item>
                        </div>
                    </a-row>
                    <a-row class="row-space-32">
                        <div class="button-regular" style="color: #223273;">Confirm password</div>
                        <div class="row-space-16">
                            <a-input v-if="dashmoteUser" disabled placeholder="Confirm password" style="height: 46px;"></a-input>
                            <a-form-item v-else style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['confirmPassword', { rules: [{required: false, message: 'Confirm your new password' }] }]" type="password"/></a-form-item>
                        </div>
                    </a-row>
                    <a-row class="row-space-48"><a-form-item>
                        <a disabled href="" class="forgot-pwd-style"></a><!-- No option for forgotPassword now -->
                        <a-button v-if="dashmoteUser" disabled class="primary-button-style-total" style="float: right;">Update Password</a-button>
                        <a-button v-else class="primary-button-style-total" html-type="submit" style="float: right;">Update Password</a-button></a-form-item>
                    </a-row>
                </a-row>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </div>
</template>

<script>
import { auth, db } from '@/firebase';
const mobile = require('is-mobile');

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  name: 'Account',
  data() {
    return {
      mobile,
      overlay: false,
      forgotPasswordText: 'Forgot your password?',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      displayName: '',
      email: '',
      profilePhotoURL: require('../../assets/icon_user.svg'),
      profilePhotoPlaceholder: require('../../assets/icon_user.svg'),
      username: '',
      loading: false,
      imageUrl: '',
      dashmoteUser: false,
      company: '',
      googlePhotoURL: require('../../assets/icon_user.svg'),
      uid: '',
    };
  },
  methods: {
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.profilePhotoURL = imageUrl;
          this.loading = false;
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG or PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      if (isJpgOrPng && isLt2M) {
        this.uploadImage(file);
      }
      return isJpgOrPng && isLt2M;
    },
    uploadImage(file) {
      const storageRef = firebase.storage().ref();
      const metadata = { contentType: file.type };
      const uploadTask = storageRef.child(`avatars/${this.uid}`).put(file, metadata);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error('Error during upload:', error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.setNewProfilePhoto(downloadURL);
            this.profilePhotoURL = downloadURL;
            this.$emit('update-header', this.displayName, this.profilePhotoURL);
          });
        }
      );
    },
    setNewProfilePhoto(profilePhotoURL) {
      db.ref(`users/${this.uid}`).update({ photoURL: profilePhotoURL }).catch((error) => {
        console.error('Error updating profile photo:', error);
      });
    },
    imageError() {
      this.profilePhotoURL = this.profilePhotoPlaceholder;
    },
    forgotPassword() {
      auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.forgotPasswordText = 'Password recovery email has been sent.';
        })
        .catch((error) => {
          console.error('Error sending password reset email:', error);
        });
    },
    getProfilePhotoFromDB() {
      db.ref(`users/${this.uid}`).once('value', (snapshot) => {
        this.profilePhotoURL = snapshot.val()?.photoURL || this.googlePhotoURL;
      });
    },
    getCompany() {
      db.ref(`users/${this.uid}`).once('value', (snapshot) => {
        this.company = snapshot.val()?.company || 'Company not set';
      });
    },
    setCompany(companyValue) {
      db.ref(`users/${this.uid}`)
        .update({ company: companyValue })
        .catch((error) => console.error('Error updating company:', error));
    },
    setDisplayNameInDB(displayNameValue) {
      db.ref(`users/${this.uid}`)
        .update({ displayName: displayNameValue })
        .catch((error) => console.error('Error updating display name:', error));
    },
    getUser() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.displayName = user.displayName || user.email.split('@')[0];
          this.email = user.email;
          this.uid = user.uid;
          this.googlePhotoURL = user.photoURL || this.googlePhotoURL;
          this.getProfilePhotoFromDB();
          this.getCompany();
          this.dashmoteUser = user.email.endsWith('@dashmote.com');
        } else {
          console.error('No user is currently logged in.');
        }
      });
    },
    setUserDetails(newDisplayName) {
      const user = auth.currentUser;
      if (user) {
        user
          .updateProfile({ displayName: newDisplayName })
          .then(() => console.log('User profile updated successfully.'))
          .catch((error) => console.error('Error updating user profile:', error));
      }
    },
    updatePassword(oldPassword, newPassword) {
      const user = auth.currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(this.email, oldPassword);

      user
        .reauthenticateWithCredential(credential)
        .then(() => user.updatePassword(newPassword))
        .then(() => this.$message.success('Password has been successfully updated'))
        .catch((error) => console.error('Error updating password:', error));
    },
    handleUpdatePassword(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.newPassword !== values.confirmPassword) {
            return this.$error({ title: 'Passwords do not match, please try again!' });
          }
          if (values.newPassword.length < 8) {
            return this.$error({ title: 'The new password must be at least 8 characters long!' });
          }
          this.updatePassword(values.currentPassword, values.newPassword);
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setUserDetails(values.displayName);
          this.setDisplayNameInDB(values.displayName);
          if (!this.dashmoteUser) {
            this.setCompany(values.company);
          }
          this.$emit('update-header', values.displayName, this.profilePhotoURL);
          this.getUser();
        }
      });
    },
  },
  created() {
    this.getUser();
  },
};
</script>

<style scoped>
@import '../Projects/Projects.css';
@import '../Dashboards/DetailPage.css';
@import './Account.css';
</style>
