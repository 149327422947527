/* eslint-disable */
import Firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

// Initialize Firebase App
const firebaseApp = Firebase.initializeApp({
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID
});

// Export Database and Auth Instances
export const db = firebaseApp.database();
export const auth = firebaseApp.auth();
export const GoogleAuthProvider = Firebase.auth.GoogleAuthProvider; // Export GoogleAuthProvider