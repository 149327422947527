<template>
   <a-row class="main-area">
     <a-modal class="dashCustomModal" v-model="dashModalFileUploadedVisible" :title="dashModalFileUploadTitle">
      <div slot="closeIcon"><img :src="require('../../../assets/setupstepicon/closeModalCross.svg')"/></div>
      <div class="dashCustomModalText">You can now access it in the data room. <br/>Note: It will also be visible to your delivery lead.</div>
      <template slot="footer">
        <a-button class="confirmButton" key="submit" type="primary" @click="handleDashModalFileUploaded">
          Ok, I get it!
        </a-button>
      </template>
    </a-modal>
    <a-modal class="dashCustomModal" v-model="dashModalStepSubmittedVisible" :title="dashModalStepSubmittedTitle">
      <div slot="closeIcon"><img :src="require('../../../assets/setupstepicon/closeModalCross.svg')"/></div>
      <div class="dashCustomModalText">Your delivery lead will review this task soon. <br/>Keep an eye on your email!</div>
      <template slot="footer">
        <a-button class="confirmButton" key="submit" type="primary" @click="handleDashModalSubmitStep">
          Ok, I get it!
        </a-button>
      </template>
    </a-modal>
    <!-- Main code -->
      <a-col class="content">
         <a-row class="row-space-24">
            <div class="dashboard-overview-setup-steps-content">
               <a-collapse @change="trackCollapse($event)" accordion expand-icon-position="right" :bordered="false" style="background: #F2F6FC;">
                  <a-collapse-panel
                     class="active-step"
                     v-for="(item, index) of itemsList"
                     :style="customStyle"
                     :key="String(item.orderID)"
                     >
                     <div slot="header" style="position: absolute; margin-left: -168px; margin-top: 12px;">
                        <a-tooltip v-if="item.status === 'open'" title="Please read information and submit required step">
                           <img style="margin-bottom: 2px; width: 24px; height: 24px;" :src="require('../../../assets/setupstepicon/OpenIcon.svg')"/>
                           <span class="status-text" style="margin-left: 16px;">To be done</span>
                        </a-tooltip>
                        <a-tooltip v-if="item.status === 'action'" title="Please can you review our comments and submit the required changes">
                           <img style="margin-bottom: 2px; width: 24px; height: 24px;" :src="require('../../../assets/setupstepicon/ActionIcon.svg')"/>
                           <span class="status-text" style="margin-left: 16px;">Action Needed</span>
                        </a-tooltip>
                        <a-tooltip v-if="item.status === 'done'" title="All good here, nothing to be done anymore">
                           <img style="margin-bottom: 2px; width: 24px; height: 24px;" :src="require('../../../assets/setupstepicon/DoneIcon.svg')"/>
                           <span class="status-text-completed" style="margin-left: 16px;">Completed</span>
                        </a-tooltip>
                        <a-tooltip v-if="item.status === 'submitted'" title="Dashmote is reviewing your information, nothing to be done from your side">
                           <img style="margin-bottom: 2px; width: 24px; height: 24px;" :src="require('../../../assets/setupstepicon/IconSubmitted.svg')"/>
                           <span class="status-text" style="margin-left: 16px;">Submitted</span>
                        </a-tooltip>
                     </div>
                     <div v-if="(item.status ==='open' || item.status ==='submitted' || item.status ==='action') " slot="header" style="margin-left:12px; margin-top:12px;" v-html="item.title"></div>
                     <div v-if="item.status === 'done'" slot="header" style="margin-left:12px; margin-top:12px; font-family: Roboto; font-style: normal; font-weight: 500;font-size: 14px; line-height: 24px;color: #989BAC;" v-html="item.title"></div>
                     <v-md-preview style="margin-left: 12px" :text="item.bodyText"></v-md-preview>
                     <div class="uploadSetupStep" v-if="item.hasUploadButton">
                        <a-upload
                            v-if="item.hasAgreeButton && (item.status ==='open' || item.status ==='action') "
                           style="height: 32px; margin-left:12px; position: relative;"
                           :customRequest="dummyRequest"
                           :multiple="true"
                           :file-list="item.fileList"
                           :before-upload="beforeUpload"
                           @change="handleChange(index, $event)"
                           >
                           <a-button class="third-button-style-total"><img class="buttonIcon" style="margin-bottom: 2px; height: 16px; width 16px; margin-right: 8px;" :src="require('../../../assets/setupstepicon/upload-icon.svg')"/>Upload files</a-button>
                        </a-upload>
                        <a-button v-if="item.hasAgreeButton && (item.status ==='submitted' || item.status ==='done') " disabled class="primary-button-disabled"><img class="buttonIcon" style="margin-bottom: 2px; height: 16px; width 16px; margin-right: 8px;" :src="require('../../../assets/setupstepicon/upload-icon.svg')"/>Upload files</a-button>
                            <span v-if="item.hasAgreeButton && (item.status ==='open' || item.status ==='action') " style="margin-right: calc(100% - 600px); margin-left:16px;/* font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14px; line-height: 22px; color: #989BAC;">Max 300MB</span>
                            <span v-if="item.hasAgreeButton && (item.status ==='submitted' || item.status ==='done') " style="margin-right: calc(100% - 620px); margin-left:16px;*/ font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14px; line-height: 22px; color: #989BAC;">Max 300MB</span>
                            <span class="learn-more-text" @click="goToLink('http://help.dashmote.com/en/articles/5234165-client-data-protection')">Learn more</span><span class="about-our-policy-text">about our security policy</span>
                            <a-button v-if="item.hasAgreeButton && (item.status ==='open' || item.status ==='action') " class="primary-button-style-total" @click="submitStep(index, item.id)" style="margin-left: 24px; margin-bottom: 12px;margin-right:10px;"><img class="buttonIcon" style="height: 16px; width 16px; margin-right: 8px;margin-bottom: 2px;" :src="require('../../../assets/setupstepicon/check.svg')"/>Submit</a-button>
                            <a-button v-if="item.hasAgreeButton && (item.status ==='submitted' || item.status ==='done') " disabled class="primary-button-disabled" style="margin-left: 24px; margin-bottom: 12px;margin-right:10px;"><img class="buttonIcon" style="height: 16px; width 16px; margin-right: 8px; margin-bottom: 2px;" :src="require('../../../assets/setupstepicon/check.svg')"/>Submitted</a-button>
                     </div>
                     <a-button v-if="item.embedURL" class="third-button-style-total" @click="openBigger(item.embedURL, item.id, item.status, item.hasAgreeButton)" style="margin-left: 20px; float:right; margin-bottom: 10px; margin-right:10px;"><img style="margin-bottom:4px;margin-right:4px;" class="buttonIcon" width="12px;" :src="require('../../../assets/setupstepicon/expand-outline.svg')"/>Fullscreen</a-button>
                     <iframe v-if="item.embedURL" class="airtable-embed" :src="item.embedURL" frameborder="0" onmousewheel="" width="97%" height="300px" style="background: transparent; margin-left: 12px; border: 1px solid #ccc;"></iframe>
                     <div v-if="item.embedURL" style="white-space: pre-wrap; display: flex; align-items: center;justify-content: space-between; margin-top: 12px;">
                        <div style="margin-left: 10px;"><span class="learn-more-text" @click="goToLink('http://help.dashmote.com/en/articles/5234165-client-data-protection')">Learn more</span><span class="about-our-policy-text">about our security policy</span></div>
                        <a-button v-if="item.hasAgreeButton && !item.hasUploadButton && (item.status ==='open' || item.status ==='action') " class="primary-button-style-total" @click="submitStep(index, item.id)" style="margin-right:10px;"><img class="buttonIcon" style="height: 16px; width 16px; margin-right: 8px;margin-bottom: 2px;" :src="require('../../../assets/setupstepicon/check.svg')"/>Submit</a-button>
                        <a-button v-if="item.hasAgreeButton && (item.status ==='submitted' || item.status ==='done') " disabled class="primary-button-disabled" style="margin-right:10px;"><img class="buttonIcon" style="height: 16px; width 16px; margin-right: 8px;margin-bottom: 2px; " :src="require('../../../assets/setupstepicon/check.svg')"/>Submitted</a-button>
                     </div>
                     <a-modal v-if="item.embedURL" v-model="visible" title="" width=80% @ok="handleOk(index, item.id, $event)" destroyOnClose>
                       <iframe class="airtable-embed" :src="modalURL" frameborder="0" onmousewheel="" width="100%" style="background: transparent; border: 1px solid #ccc; margin-top:20px; height: 65vh;"></iframe>
                        <template slot="footer">
                           <a-button key="back" class="third-button-style-total" @click="handleCancel">
                              Close
                           </a-button>
                           <a-button v-if="modalHasAgreeButton && (modalStatus ==='open' || modalStatus ==='action') " key="submit" type="primary" class="primary-button-style-total" @click="handleOk(index, item.id, $event)">
                              Submit
                           </a-button>
                        </template>
                     </a-modal>
                  </a-collapse-panel>
               </a-collapse>
            </div>
         </a-row>
      </a-col>
   </a-row>
</template>

<script>
import { auth, db } from '../../../firebase'
import moment from 'moment'
import axios from 'axios'
const AWS = require('aws-sdk')

// Configure the S3 client
const s3 = new AWS.S3({
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.VUE_APP_AWS_REGION,
  ACL: 'public-read'
})

export default {
  name: 'DashboardOverview',
  data() {
    return {
      overlay: false,
      modalURL: '',
      modalID: '',
      modalStatus: '',
      modalHasAgreeButton: '',
      visible: false,
      HTMLcontent: null,
      dashboardName: '',
      itemsList: [],
      hasOpenSteps: false,
      text: 'We need you to agree on the brand list. Please check the list below and click on Agree.',
      customStyle:
        'margin-bottom: 24px; background: #FFFFFF; max-height600px; box-sizing: border-box; box-shadow: 0px 4px 10px rgba(120, 120, 120, 0.1), 0px 4px 5px rgba(151, 151, 151, 0.1); min-height:70px; border-radius: 8px; float: right; min-width:635px; width: calc(100% - 205px); margin-left: 205px;',
      dashModalFileUploadedVisible: false,
      dashModalFileUploadTitle: 'Congratulations! \nYour file is uploaded.',
      dashModalStepSubmittedVisible: false,
      dashModalStepSubmittedTitle: 'Congratulations! \nYour progress is saved!'
    }
  },
  methods: {
    /**
     * Returns the current user's email if authenticated, otherwise 'Unknown User'.
     */
    getAuthUserEmail() {
      return auth.currentUser ? auth.currentUser.email : 'Unknown User'
    },

    trackCollapse(event) {
      if (typeof event !== 'undefined') {
        this.$mixpanel.track('Opened task', {
          openTime: moment().format(),
          taskTitle: this.itemsList[event - 1].title,
          embedURL: this.itemsList[event - 1].embedURL,
          hasUploadButton: this.itemsList[event - 1].hasUploadButton,
          dashboard: this.dashboardName
        })
      }
    },
    destroyAllModals() {
      this.Modal.destroyAll()
    },
    goToLink(sourceURL) {
      window.open(sourceURL, '_blank')
      this.$mixpanel.track('Clicked learn more about Data Privacy Policy', {
        linkURL: sourceURL
      })
    },
    showDashModalFileUploaded() {
      this.dashModalFileUploadedVisible = true
    },
    handleDashModalFileUploaded() {
      this.dashModalFileUploadedVisible = false
    },
    showDashModalSubmitStep() {
      this.dashModalStepSubmittedVisible = true
    },
    handleDashModalSubmitStep() {
      this.dashModalStepSubmittedVisible = false
    },

    dummyRequest({ file, onSuccess }) {
      // Simulates a successful upload
      setTimeout(() => {
        onSuccess('ok')
      }, 0)
    },

    /**
     * Handle file list changes on antd upload component
     */
    handleChange(index, info) {
      let fileList = [...info.fileList]
      // Limit to the two most recent files
      fileList = fileList.slice(-2)

      // If there's a response, the file url can be attached
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })
      this.itemsList[index].fileList = fileList
    },

    /**
     * Validates if a filetype is allowed
     */
    validateFiletype(filetype) {
      const allowedTypes = [
        'application/excel',
        'application/vnd.ms-excel',
        'application/x-excel',
        'application/x-msexcel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/doc',
        'application/ms-doc',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/gzip',
        'application/zip',
        'text/tab-separated-values',
        'application/pdf',
        'application/json',
        'text/csv',
        'application/mspowerpoint',
        'application/powerpoint',
        'application/vnd.ms-powerpoint',
        'application/x-mspowerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ]
      return allowedTypes.includes(filetype)
    },

    /**
     * Ant Design's beforeUpload hook to validate file size/type before uploading
     */
    beforeUpload(file) {
      const correctFileType = this.validateFiletype(file.type)
      const isLt = file.size / 1024 / 1024 < 300
      const userEmail = this.getAuthUserEmail()

      if (!correctFileType) {
        this.$message.error(
          'Unsupported filetype. Allowed: CSV, JSON, TSV, EXCEL, WORD, POWERPOINT, PDF, ZIP, GZIP'
        )
        this.notifySlackUnsupportedFile(file, userEmail)
      } else if (!isLt) {
        this.$message.error('File must be smaller than 300MB.')
        this.notifySlackLargeFile(file, userEmail)
      }

      if (correctFileType && isLt) {
        this.uploadS3File(file)
      }
      // Return false to prevent default antd upload
      return false
    },

    /**
     * Sends Slack notification if file is unsupported
     */
    notifySlackUnsupportedFile(file, userEmail) {
      axios.post(
        process.env.VUE_APP_SLACK_WEBHOOK,
        {
          text: `User tried to upload an unsupported filetype.
            User: ${userEmail}
            Project: https://app.dashmote.com/project/${this.$route.params.projectId}
            Dashboard Name: ${this.dashboardName}

            Filename: ${file.name}
            Unsupported Filetype: ${file.type}
            Filesize: ${Math.round(file.size / 1024 / 1024)} MB`
        },
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
    },

    /**
     * Sends Slack notification if file is too large
     */
    notifySlackLargeFile(file, userEmail) {
      axios.post(
        process.env.VUE_APP_SLACK_WEBHOOK,
        {
          text: `User tried to upload a file larger than 300MB.
            User: ${userEmail}
            Project: https://app.dashmote.com/project/${this.$route.params.projectId}
            Dashboard Name: ${this.dashboardName}

            Filename: ${file.name}
            Filetype: ${file.type}
            Filesize: ${Math.round(file.size / 1024 / 1024)} MB`
        },
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
    },

    /**
     * Generates a random ID
     */
    makeid(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
      }
      return result
    },

    /**
     * Uploads file to S3
     */
    uploadS3File(file) {
      const fileExtention = file.name.split('.').pop()
      const madeID = this.makeid(10)
      const fileKey = `${madeID}.${fileExtention}`
      const params = {
        Bucket: `dash-frontend-dev/DATAROOM/${this.$route.params.projectId}/${this.$route.params.dashboardId}`,
        Key: fileKey,
        Body: file
      }

      s3.upload(params, (s3Err, data) => {
        if (s3Err) throw s3Err
        this.setDataroomItem(madeID, file.name, fileKey, file.type, data.Location)
        this.showDashModalFileUploaded()
      })
    },

    /**
     * Saves file metadata in DB
     */
    setDataroomItem(id, filename, filekey, filetype, fileLocation) {
      const userEmail = this.getAuthUserEmail()
      const dataroomItemData = {
        filename,
        status: 'submitted',
        uploader: userEmail,
        timestamp: moment().format('MMM Do YYYY'),
        filekey,
        filetype,
        fileLocation
      }

      this.$mixpanel.track('Uploaded File', {
        filename,
        filetype,
        projectId: this.$route.params.projectId,
        dashboardId: this.$route.params.dashboardId
      })
      this.fireUploadSlackNotification(filename, filekey, filetype, fileLocation, userEmail)
      this.fireToSlackApp('fileUploaded', 1, filename, fileLocation)

      db.ref(
        `projects/${this.$route.params.projectId}/dashboards/${this.$route.params.dashboardId}/settings/dataroom/${id}`
      )
        .update(dataroomItemData)
        .then(() => {
          console.log('success')
        })
        .catch((error) => {
          console.error('ERROR saving dataroom item:', error)
        })
    },

    /**
     * Notifies Slack about a successful file upload
     */
    fireUploadSlackNotification(filename, filekey, filetype, fileLocation, userEmail) {
      axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
        text: `User just uploaded a new file to the dataroom from My Tasks
      User: ${userEmail}
      Project:  https://app.dashmote.com/project/${this.$route.params.projectId}
      Dashboard Name: ${this.dashboardName}
      Dashboard url: https://app.dashmote.com/project/${this.$route.params.projectId}/${this.$route.params.dashboardId}
      Settings url: https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings
      
      Filename: ${filename}
      S3 Filekey: ${filekey}
      S3 Location: ${fileLocation}
      Filetype: ${filetype}`
      }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    },

    /**
     * Opens a modal with the step content
     */
    showModal(url, id, status, hasAgreeButton) {
      this.modalURL = url
      this.modalID = id
      this.modalStatus = status
      this.modalHasAgreeButton = hasAgreeButton
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    handleOk(index, stepId) {
      this.submitStep(index, stepId)
      this.visible = false
    },

    /**
     * Submits the current step/task
     */
    submitStep(idx, actualId) {
      this.itemsList[idx].status = 'submitted'
      this.fireSlackNotification(idx)
      this.fireToSlackApp('taskSubmitted', idx)
      this.setStatus(actualId, 'submitted')
      this.$mixpanel.track('Submitted task', {
        submitTime: moment().format(),
        taskTitle: this.itemsList[idx].title,
        dashboard: this.dashboardName
      })
      window.Intercom('trackEvent', 'submitted-task')
      this.showDashModalSubmitStep()
    },

    /**
     * Updates step status in Firebase
     */
    setStatus(id, status) {
      db.ref(
        `projects/${this.$route.params.projectId}/dashboards/${this.$route.params.dashboardId}/settings/setupsteps/${id}`
      )
        .update({ status })
        .then(() => {
          this.$emit('check-for-open-task')
        })
        .catch((error) => {
          console.error('ERROR updating step status:', error)
        })
    },

    /**
     * Notifies Slack that a user has submitted a task
     */
    fireSlackNotification(stepId) {
      const userEmail = this.getAuthUserEmail()
      axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
        text: `User just submitted a setup step
      User: ${userEmail}
      Project:  https://app.dashmote.com/project/${this.$route.params.projectId}
      Dashboard Name: ${this.dashboardName}
      Dashboard url: https://app.dashmote.com/project/${this.$route.params.projectId}/${this.$route.params.dashboardId}
      Step title: ${this.itemsList[stepId].title}
      Settings url: https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings`
      }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    },

    /**
     * Sends additional Slack notifications using a Slack App
     */
    fireToSlackApp(messageType, id, filename, fileLocation) {
      const slackAppHook = 'https://hooks.slack.com/services/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'

      if (messageType === 'taskSubmitted') {
        axios.post(
          slackAppHook,
          this.formatSubmitTaskSlackMessage(id),
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        )
      } else if (messageType === 'fileUploaded') {
        axios.post(
          slackAppHook,
          this.formatUploadFileSlackMessage(filename, fileLocation),
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        )
      }
    },

    /**
     * Formats the Slack message for a submitted task
     */
    formatSubmitTaskSlackMessage(id) {
      const userEmail = this.getAuthUserEmail()
      return `{
"blocks": [{
  "type": "header",
  "text": {
    "type": "plain_text",
    "text": "User just submitted a task",
    "emoji": true
  }
},
{
  "type": "section",
  "text": {
    "type": "mrkdwn",
    "text": "*Dashboard Name:*  ${this.dashboardName}\\n *Task Title:* ${this.itemsList[id].title}\\n *User:* ${userEmail}"
  }
},
{
  "type": "divider"
},
{
  "type": "section",
  "text": {
    "type": "mrkdwn",
    "text": "Go to Dashboard Management in the Admin Application ->"
  },
  "accessory": {
    "type": "button",
    "text": {
      "type": "plain_text",
      "text": "Open Admin App",
      "emoji": true
    },
    "value": "open_dashboard_management_admin",
    "url": "https://admin.getdashmote.com/dashboard/detail/${this.$route.params.projectId}/settings/${this.$route.params.dashboardId}"
  }
},
{
  "type": "section",
  "text": {
    "type": "mrkdwn",
    "text": "Go to task in the Client Application ->"
  },
  "accessory": {
    "type": "button",
    "text": {
      "type": "plain_text",
      "text": "Open Client App",
      "emoji": true
    },
    "value": "open_dashboard_management_tasks_client",
    "url": "https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings/tasks"
  }
},
{
  "type": "divider"
}]
}`
    },

    /**
     * Formats the Slack message for a file upload
     */
    formatUploadFileSlackMessage(filename, fileLocation) {
      const userEmail = this.getAuthUserEmail()
      return `{
"blocks": [
  {
    "type": "header",
    "text": {
      "type": "plain_text",
      "text": "User just uploaded a file to the dataroom",
      "emoji": true
    }
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "*Dashboard Name:*  ${this.dashboardName}\\n *Filename:* ${filename}\\n *User:* ${userEmail}"
    }
  },
  {
    "type": "divider"
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "*S3 Location:* ${fileLocation}"
    }
  },
  {
    "type": "divider"
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "Go to Dashboard Management in the Admin Application ->"
    },
    "accessory": {
      "type": "button",
      "text": {
        "type": "plain_text",
        "text": "Open Admin App",
        "emoji": true
      },
      "value": "open_dashboard_management_admin",
      "url": "https://admin.getdashmote.com/dashboard/detail/${this.$route.params.projectId}/settings/${this.$route.params.dashboardId}"
    }
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "Go to Dashboard Management in the Client Application ->"
    },
    "accessory": {
      "type": "button",
      "text": {
        "type": "plain_text",
        "text": "Open Client App",
        "emoji": true
      },
      "value": "open_dashboard_management_client",
      "url": "https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings"
    }
  },
  {
    "type": "divider"
  }
]
}`
    },

    /**
     * Opens a modal for a given step
     */
    openBigger(url, id, status, hasAgreeButton) {
      this.showModal(url, id, status, hasAgreeButton)
    },

    /**
     * Sorts items by orderID
     */
    sortByOrderID() {
      this.itemsList.sort((a, b) => (a.orderID > b.orderID ? 1 : -1))
    },

    /**
     * Fetches data for the current dashboard
     */
    getData() {
      db.ref(`projects/${this.$route.params.projectId}/dashboards/${this.$route.params.dashboardId}`)
        .once('value')
        .then((snapshot) => {
          const dashData = snapshot.val()
          if (dashData) {
            this.dashboardName = dashData.name
            if (dashData.settings && dashData.settings.setupsteps) {
              Object.entries(dashData.settings.setupsteps).forEach(([stepId, stepValue]) => {
                this.itemsList.push({
                  id: stepId,
                  orderID: stepValue.orderID,
                  title: stepValue.title,
                  status: stepValue.status,
                  previewText: stepValue.previewText,
                  bodyText: stepValue.bodyText,
                  embedURL: stepValue.embedURL,
                  hasAgreeButton: stepValue.hasAgreeButton,
                  hasUploadButton: stepValue.hasUploadButton,
                  fileList: []
                })
              })
              this.sortByOrderID()
            }
          }
        })
        .catch((error) => {
          console.error('Error fetching dashboard data:', error)
        })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
@import './DashboardSettings.css';
</style>
