<template>

<!-- Mobile layout (Header, content and sidebar) -->
  <a-layout v-if="path && mobile()" id="app">
      <a-layout-sider style="position: absolute; height: 100%; z-index:1000; background-color: #373B53;" v-model="collapsed" :trigger="null" collapsible collapsedWidth="0px" width="264px">
      <Sidebar ref="sidebar" @toggle-side-menu-from-sidebar="toggleMenuFromSidebar"></Sidebar>
    </a-layout-sider>

      <a-layout>
        <a-layout-header style="background: #f2f6fc; padding: 0">
        <Header ref="header" @highlight-dashmote-university="highlightDashmoteUniversityItemFromHeader" @toggle-side-menu="toggleMenu"></Header>
      </a-layout-header>
        <a-layout-content>
          <router-view ref="routerview" @update-header="updateHeader" @hide-header="hideHeader" @show-header="showHeader"></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>

<!-- Desktop layout with path (Header and content)-->
    <a-layout v-else-if="path" id="app">
      <a-layout-sider style="position: absolute; height: 100%; z-index:1000; background-color: #373B53;" v-model="collapsed" :trigger="null" collapsible collapsedWidth="0px" width="264px">
      <Sidebar ref="sidebar" @toggle-side-menu-from-sidebar="toggleMenuFromSidebar" ></Sidebar>
    </a-layout-sider>
      <a-layout>
      <a-layout-header style="background: #f2f6fc; padding: 0;">
          <Header ref="header" @highlight-dashmote-university="highlightDashmoteUniversityItemFromHeader" @toggle-side-menu="toggleMenu"></Header>
        </a-layout-header>

        <a-layout-content>
          <router-view ref="routerview" @update-header="updateHeader" @hide-header="hideHeader" @show-header="showHeader"></router-view>
        </a-layout-content>
        </a-layout>
    </a-layout>

<!-- Desktop layout without path (Only content)-->
    <a-layout v-else id="app">
        <a-layout-content>
          <router-view></router-view>
        </a-layout-content>
    </a-layout>
</template>

<script>
import { auth } from './firebase'; // Import auth from firebase setup
import Header from './components/layout/Header/Header';
import Sidebar from './components/layout/Sidebar/Sidebar';
const mobile = require('is-mobile');

export default {
  name: 'App',
  components: {
    Header,
    Sidebar
  },
  data() {
    return {
      mobile,
      toggleDisable: false,
      collapsed: true,
      path: false,
      menu: [
        {
          header: true,
          title: 'Main Navigation',
          hiddenOnCollapse: false
        },
        {
          href: '/',
          title: 'Dashboard'
        },
        {
          href: '/account',
          title: 'Account'
        },
        {
          href: '/login',
          title: 'Log Out'
        }
      ]
    };
  },
  watch: {
    '$route'() {
      if (localStorage.accessToken) {
        this.path = true;
      } else {
        this.path = false;
      }
    }
  },
  methods: {
    toggleMenu() {
      if (!mobile) {
        this.$refs.sidebar.collapsed = !this.$refs.sidebar.collapsed;
        this.$refs.routerview.overlay = !this.$refs.routerview.overlay;
      } else {
        if (!this.toggleDisable) {
          this.$refs.sidebar.collapsed = !this.$refs.sidebar.collapsed;
          this.$refs.routerview.overlay = !this.$refs.routerview.overlay;
        }
        this.toggleDisable = true;
        setTimeout(() => {
          this.toggleDisable = false;
        }, 100);
      }
    },
    highlightDashmoteUniversityItemFromHeader() {
      this.$refs.sidebar.highlightDashmoteUniversityItem();
    },
    toggleMenuFromSidebar() {
      this.$refs.header.toggleParentSideMenu();
    },
    updateHeader(displayName, profilePhotoURL) {
      this.$refs.header.updateUser(displayName, profilePhotoURL);
    },
    hideHeader() {
      this.$refs.header.hideHeader();
    },
    showHeader() {
      this.$refs.header.showHeader();
    },
    dashmoteUniversityRedirect() {
      const host = window.location.host;
      const parts = host.split('.');
      if (parts[0] === 'university') {
        console.log('User coming from University domain.');
        this.$mixpanel.track(
          'Redirected user from university.dashmote.com to app.dashmote.com/university'
        );
        window.location.assign('https://app.dashmote.com/university');
      }
    },
    initializeIntercom() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.$intercom.boot({
            name: user.displayName || 'User',
            email: user.email
          });
          this.$intercom.hide();
        } else {
          this.$intercom.boot();
          this.$intercom.hide();
        }
      });
    }
  },
  created() {
    this.dashmoteUniversityRedirect();
    if (localStorage.accessToken) {
      this.path = true;
    } else {
      this.path = false;
    }
  },
  mounted() {
    this.initializeIntercom();
  }
};
</script>

<style scoped>
@import './layout.css';
</style>

<style>
#app {
  background-color: #f2f6fc;
  height: 100%;
}
.button-style-total {
  color: #1890ff !important;
  background-color: #ffffff !important;
  border: 1px solid #1990FF !important;
}
.button-style-total:hover {
  color: #fff !important;
  border-color: #1990FF !important;
  background-color: #1990FF !important;
  box-shadow: 0px 4px 5px rgba(24, 144, 255, 0.15);
}
.primary-button-style-total {
  color: #fff !important;
  border-color: #1990FF !important;
  background-color: #1990FF !important;
}

.primary-button-style-total .buttonIcon {
    filter: invert(100%) sepia(6%) saturate(1%) hue-rotate(291deg) brightness(106%) contrast(101%);
}

.secondary-button-style-total {
  color: #fff !important;
  border-color: #57acfc !important;
  background-color: #57acfc !important;
}

/* Make button arrow white #FFFFFF */
.primary-button-style-total .arrow-image {
  transition: filter 0s ease-in-out;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(135deg) brightness(103%) contrast(101%);
}

.primary-button-style-total:hover {
  color: #1990FF !important;
  background-color: #ffffff !important;
  border: 1px solid #1990FF !important;
  box-shadow: 0px 4px 5px rgba(24, 144, 255, 0.15);
}

.primary-button-style-total:hover .buttonIcon {
    filter: invert(42%) sepia(78%) saturate(2266%) hue-rotate(191deg) brightness(102%) contrast(101%);
}

.secondary-button-style-total:hover {
  color: #57acfc !important;
  background-color: #ffffff !important;
  border: 1px solid #57acfc !important;
  box-shadow: 0px 4px 5px rgba(24, 144, 255, 0.15);
}

.third-button-style-total:hover {
  color: #fff !important;
  border-color: #1990FF !important;
  background-color: #1990FF !important;
}

.third-button-style-total:hover .buttonIcon {
    filter: invert(100%) sepia(6%) saturate(1%) hue-rotate(291deg) brightness(106%) contrast(101%);
}

.third-button-style-total {
  color: #1990FF !important;
  background-color: #ffffff !important;
  border: 1px solid #1990FF !important;
  box-shadow: 0px 4px 5px rgba(24, 144, 255, 0.15);

  /* /Button Text */

font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 14px;
/* identical to box height, or 100% */

letter-spacing: 0.005em;

/* Bright Blue */

color: #1990FF;
}

.third-button-style-total .buttonIcon {
    filter: invert(42%) sepia(78%) saturate(2266%) hue-rotate(191deg) brightness(102%) contrast(101%);
}

/* Make button arrow Blue #1990FF */
.primary-button-style-total:hover .arrow-image {
  transition: filter 0s ease-in-out;
  filter: invert(51%) sepia(72%) saturate(3723%) hue-rotate(190deg) brightness(99%) contrast(105%);
}

.primary-button-disabled {
  background: #FFFFFF;
  border: 1px solid #989BAC;
  box-sizing: border-box;
  border-radius: 4px;
}

.primary-button-disabled .buttonIcon {
  filter: invert(68%) sepia(7%) saturate(575%) hue-rotate(194deg) brightness(91%) contrast(87%);
}

.dash-header-three {
  /* H3 */
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */
}

.dash-header-four {
  /* H4 */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.0015em;
}

.dash-text-button {
  /* /Button Text */

font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 14px;
/* identical to box height, or 100% */

letter-spacing: 0.005em;

border-bottom: 1px solid #1990FF;
}

</style>
