<template>
  <a-row class="main-area">
    <a-modal class="dashCustomModal" v-model="dashModalFileUploadedVisible" :title="dashModalFileUploadTitle">
      <div slot="closeIcon"><img :src="require('../../../assets/setupstepicon/closeModalCross.svg')" /></div>
      <div class="dashCustomModalText">You can now access it in the data room. <br />Note: It will also be visible to
        your project manager.</div>
      <template slot="footer">
        <a-button class="confirmButton" key="submit" type="primary" @click="handleDashModalFileUploaded">
          Ok, I get it!
        </a-button>
      </template>
    </a-modal>
    <!-- Main Content -->
    <a-col class="content">
      <div class="mydataroom-search">
        <div class="search-style"><a-input-search placeholder="Search on file name"
            style="float: right;font-weight: normal;" class="project-input-search" @change="onSearch" /></div>
      </div>
      <a-row class="row-space-24">
        <div class="dashboard-overview-mydataroom">
          <a-list :grid="{ gutter: 16, column: columnsize }" :data-source="filterData">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card v-if="!item.uploadCard" style="width:100%;" class="data-card" :key="String(item.id)">
                <div style="white-space: nowrap;">
                  <span class="data-card-title">
                    <a-tooltip :title=item.title>
                      <span class="title-text">{{ item.title }}</span>
                    </a-tooltip>
                    <!-- State will never be shown, to be removed later -->
                    <a-tooltip v-if="false" title="Sorry! You don’t have permission to download.">
                      <img style="float: right; margin-right: -16px; height: 18px; width 14px;"
                        :src="require('../../../assets/setupstepicon/download_icon_disabled.svg')" />
                    </a-tooltip>
                    <!-- Until here -->
                    <img v-else-if="item.downloadable"
                      style="float: right; margin-right: -16px; height: 18px; width 14px; cursor: pointer;"
                      :src="require('../../../assets/setupstepicon/download_icon_active.svg')"
                      @click="getS3File(item.url, item.title)" />
                    <div
                      style="font-family: Roboto;font-style: normal;font-weight: 500;font-size: 14px;line-height: 14px;letter-spacing: 0.005em;color: #989BAC; white-space: nowrap; text-overflow: ellipsis; width: 100%; display: block; overflow: hidden">
                      {{ item.uploader }}</div>
                  </span>
                </div>
                <div style="margin-top:8px; margin-left: -8px; margin-right: -8px;"><img
                    v-if="item.filetype === 'application/pdf'"
                    :src="require('../../../assets/setupstepicon/pdf.svg')" />
                  <img v-else-if="item.filetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                    :src="require('../../../assets/setupstepicon/excel.svg')" />
                  <img v-else :src="require('../../../assets/setupstepicon/unknown.svg')" />
                  <div style="float: right">{{ item.timestamp }}</div>
                </div>
              </a-card>
              <a-upload-dragger v-else name="file" :before-upload="beforeUpload" :show-upload-list="false"
                :multiple="false" action="https://www.mocky.io/v2/5cc8019d300000980a055e76" @change="handleChange">
                <a-card class="upload-card border" :bordered="false">
                  <center style="margin-top: -8px;">
                    <div class="uploadButton" />
                    <div><span class="drag-drop-text">Drag and drop</span><span class="browse-files-text"> or browse
                        files</span></div>
                    <div class="max-size-text">Max file size 300MB</div>
                  </center>
                </a-card>
              </a-upload-dragger>
            </a-list-item>
          </a-list>
        </div>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { db } from '../../../firebase';
import { auth } from '../../../firebase'; // Import the `auth` object
const R = require('ramda');
const AWS = require('aws-sdk');

const s3 = new AWS.S3({
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.VUE_APP_AWS_REGION,
  ACL: 'public-read',
});

export default {
  name: 'DashboardMyDataRoom',
  data() {
    return {
      overlay: false,
      columnsize: 4,
      dashboardName: '',
      itemsList: [],
      hasOpenSteps: false,
      search: '',
      dashModalFileUploadedVisible: false,
      dashModalFileUploadTitle: 'Congratulations! \nYour file is uploaded.',
    };
  },
  methods: {
    showDashModalFileUploaded() {
      this.dashModalFileUploadedVisible = true;
    },
    handleDashModalFileUploaded() {
      this.dashModalFileUploadedVisible = false;
    },
    onSearch(e) {
      this.search = e.target.value;
    },
    isContain(item) {
      if (this.search) {
        try {
          return item.title.toLowerCase().includes(this.search.toLowerCase());
        } catch (err) {
          console.error(err);
          return false;
        }
      }
      return true;
    },
    validateFiletype(filetype) {
      const allowedFiletypes = [
        'application/excel',
        'application/vnd.ms-excel',
        'application/x-excel',
        'application/x-msexcel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/doc',
        'application/ms-doc',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/gzip',
        'application/zip',
        'text/tab-separated-values',
        'application/pdf',
        'application/json',
        'text/csv',
        'application/mspowerpoint',
        'application/powerpoint',
        'application/vnd.ms-powerpoint',
        'application/x-mspowerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ];
      return allowedFiletypes.includes(filetype);
    },
    handleChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.beforeUpload(file); // Call beforeUpload for file validation and further processing
      } else {
        console.error("No file selected");
      }
    },
    beforeUpload(file) {
      const correctFileType = this.validateFiletype(file.type);
      const isLt = file.size / 1024 / 1024 < 300;

      if (!correctFileType) {
        this.$message.error('Unsupported file type');
      } else if (!isLt) {
        this.$message.error('File must be smaller than 300MB');
      } else {
        this.uploadS3File(file); // Proceed with upload
      }
    },
    handleInvalidFile(file, errorMessage) {
      this.$message.error(errorMessage);
      this.notifySlackInvalidFile(file, errorMessage);
    },
    notifySlackInvalidFile(file, errorMessage) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          axios.post(
            process.env.VUE_APP_SLACK_WEBHOOK,
            {
              text: `${errorMessage}\n
                User: ${user.email}
                Project: https://app.dashmote.com/project/${this.$route.params.projectId}
                Dashboard Name: ${this.dashboardName}\n
                Filename: ${file.name}
                Filetype: ${file.type}
                Filesize: ${Math.round(file.size / 1024 / 1024)} MB`,
            },
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
          );
        }
      });
    },
    makeid(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      return Array.from({ length }, () =>
        characters.charAt(Math.floor(Math.random() * characters.length))
      ).join('');
    },
    uploadS3File(file) {
      const fileExtention = file.name.split('.').pop();
      const madeID = this.makeid(10);
      const fileKey = `${madeID}.${fileExtention}`;
      const params = {
        Bucket: `dash-frontend-dev/DATAROOM/${this.$route.params.projectId}/${this.$route.params.dashboardId}`,
        Key: fileKey,
        Body: file,
      };

      s3.upload(params, (s3Err, data) => {
        if (s3Err) {
          console.error('S3 Upload Error:', s3Err);
        } else {
          this.setDataroomItem(madeID, file.name, fileKey, file.type, data.Location);
          this.showDashModalFileUploaded();
        }
      });
    },
    setDataroomItem(id, filename, filekey, filetype, fileLocation) {
      const dataroomItemData = {
        filename,
        status: 'submitted',
        uploader: this.getUserEmail(),
        timestamp: moment().format('MMM Do YYYY'),
        filekey,
        filetype,
        fileLocation,
      };
      db.ref(
        `projects/${this.$route.params.projectId}/dashboards/${this.$route.params.dashboardId}/settings/dataroom/${id}`
      )
        .update(dataroomItemData)
        .then(() => {
          this.clearFileList();
          this.getData();
        })
        .catch((error) => console.error('Error setting dataroom item:', error));
    },
    getUserEmail() {
      return auth.currentUser?.email || 'Unknown User';
    },
    getData() {
      db.ref(
        `projects/${this.$route.params.projectId}/dashboards/${this.$route.params.dashboardId}`
      ).once('value', (snapshot) => {
        this.dashboardName = snapshot.val()?.name || '';
        this.itemsList = Object.entries(snapshot.val()?.settings?.dataroom || {}).map(
          ([id, value]) => ({
            id,
            title: value.filename,
            status: value.status,
            uploader: value.uploader,
            timestamp: value.timestamp,
            filetype: value.filetype,
            url: value.filekey,
            downloadable: value.downloadable,
            fileLocation: value.fileLocation,
          })
        );
      });
    },
    clearFileList() {
      this.itemsList = [];
    },
  },
  computed: {
    filterData() {
      const filteredData = R.filter(this.isContain, this.itemsList);
      filteredData.sort((a, b) =>
        moment(b.timestamp, 'MMM Do YYYY').diff(moment(a.timestamp, 'MMM Do YYYY'))
      );
      return [{ uploadCard: true }, ...filteredData];
    },
  },
  mounted() {
    this.getData();
  },
  created() {
    const updateColumnSize = () => {
      if (window.innerWidth <= 885) {
        this.columnsize = 1;
      } else if (window.innerWidth > 885 && window.innerWidth <= 1177) {
        this.columnsize = 2;
      } else {
        this.columnsize = 3;
      }
    };

    updateColumnSize();
    window.addEventListener('resize', updateColumnSize);
  },
};
</script>

<style scoped>
@import './DashboardSettings.css';
</style>
