<template>
    <a-card class="project-dashboard-card"
            :bodyStyle="{ padding: '0px' }"
            hoverable
            @click="goDashboard"
    >
        <div style="display: flex;">
            <div style="width: 70px; position: relative; height: 70px;">
                    <!-- Loading state first -->
                    <div v-if="items.name=='Loading'" ><img class="dashboard-icon" :src="require('../../assets/basic-loading-icon.svg')" alt="Columbo"></div>
                    <!-- Set the correct category icon -->
                    <div v-else-if="items.productLineValue==='columbo'"><a-tooltip title="Columbo"><img class="dashboard-icon" :src="require('../../assets/categoryicon/C.svg')" alt="Columbo"></a-tooltip></div>
                    <div v-else-if="items.productLineValue==='Forge'"><a-tooltip title="The Forge"><img class="dashboard-icon" :src="require('../../assets/categoryicon/F.svg')" alt="Forge"></a-tooltip></div>
                    <div v-else-if="items.productLineValue==='Project'"><a-tooltip title="Dashboard"><img class="dashboard-icon" :src="require('../../assets/categoryicon/D.svg')" alt="Dashboard"></a-tooltip></div>
                    <div v-else-if="items.productLineValue==='Analytics'"><a-tooltip title="Analytics"><img class="dashboard-icon" :src="require('../../assets/categoryicon/A.svg')" alt="Analytics"></a-tooltip></div>
                    <!-- Should not need an else, but by not doing so will break it with legacy database data -->
                    <div v-else><a-tooltip title="Dashboard"><img :src="require('../../assets/categoryicon/D.svg')" class="dashboard-icon" alt="Dashboard"></a-tooltip></div>
            </div>
            <div style="width: 100%;">
                <a-col v-if="items.name=='Loading'" :span="24" style="line-height: 70px; left: 10px;" class="header-3"><img :src="require('../../assets/loadingBar.svg')"></a-col>
                <a-col v-else :span="24" style="line-height: 70px; left: 10px; color: #5C6075;" class="header-3">{{ projectName }}</a-col>
            </div>
        </div>
    </a-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import { auth } from '../../firebase'; // Import Firebase auth instance
const R = require('ramda');

export default {
  name: 'DashboardCardMobile',
  props: ['projectName', 'items'],
  methods: {
    getDashboardUrl() {
      if (R.has('url')(this.items)) {
        if (this.items.productLineValue === 'columbo') {
          return `/project/${this.$route.params.id}/columbo/${this.items.id}`;
        } else {
          return `/project/${this.$route.params.id}/forge/${this.items.id}`;
        }
      } else {
        return 'no dashboard url';
      }
    },
    async goDashboard() {
      const user = auth.currentUser;

      if (!user) {
        console.error('No user is currently logged in.');
        return;
      }

      if (this.getValue('counterValue') && this.formatDate(this.items.counterValue) === 'expired') {
        this.$message.error('Your dashboard license seems to be expired, please contact your CSM');
        this.$intercom.show();
        try {
          await axios.post(
            process.env.VUE_APP_SLACK_WEBHOOK,
            {
              text: `User clicked on expired dashboard
        User: ${user.email}
        Dashboard name: ${this.projectName}
        Dashboard url: https://dashmote-client-app-staging.herokuapp.com${this.getDashboardUrl()}`
            },
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
          );
        } catch (error) {
          console.error('Error sending Slack notification:', error);
        }
        return false;
      }

      if (R.has('url')(this.items)) {
        // Track opening a specific dashboard for Mixpanel.
        this.$mixpanel.track('Open Dashboard', { 'Dashboard name': this.projectName });
        if (this.items.url.startsWith('https://columbo') || this.items.url.startsWith('https://colombo')) {
          this.$store.commit('setColumboUrl', this.items.url);
          this.$router.push(`/project/${this.$route.params.id}/columbo/${this.items.id}`);
        } else if (this.items.url.startsWith('https://app.powerbi.com')) {
          this.$router.push(`/project/${this.$route.params.id}/forge/${this.items.id}`);
        }
      } else {
        this.$error({
          title: 'No dashboard URL available'
        });
      }
    },
    getValue(target) {
      return R.has(target)(this.items);
    },
    getRemainDate(text) {
      if (moment(text).diff(moment().format('MM/DD/YYYY'), 'days') < 7) {
        return [this.formatDate(text), 'red']; // Highlight red if less than 7 days
      } else {
        return [this.formatDate(text), 'black'];
      }
    },
    formatDate(text) {
      const eventDate = moment(text);
      const todaysDate = moment().format('MM/DD/YYYY');
      if (eventDate.diff(todaysDate, 'years') > 1) {
        return eventDate.diff(todaysDate, 'years') + ' years left';
      } else if (eventDate.diff(todaysDate, 'months') > 1) {
        return eventDate.diff(todaysDate, 'months') + ' months left';
      } else if (eventDate.diff(todaysDate, 'days') > 0) {
        return eventDate.diff(todaysDate, 'days') + ' days left';
      } else {
        return 'expired';
      }
    },
    getDate(date) {
      return 'Created on ' + moment(date).format('MM-DD-YYYY');
    }
  }
};
</script>
<style scoped>
@import './DetailPage.css';
@import '../Projects/Projects.css';
</style>
