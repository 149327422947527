<template>
  <div style="width:100%; margin-top:62px">
    <div>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
    {{ ModalText }}
    </a-modal>
  </div>
    <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
    <a-row v-show="pageOnFocus === 'PowerBI'" class="row" style="height:90vh;" >
      <vue-progress-bar></vue-progress-bar>
      <!--<div v-if="!loaded" class="loader"></div>--> <!-- Disabled for now -->
      <div v-if="showExportGenerator" style="width: 100%; height: 32px; background: #D3D6E3; display:flex; justify-content: center; align-items: center; border-bottom: 1px solid #b7b7b7;">
        <span @click="goToExportGenerator" class='header-3' style="cursor: pointer;">Need just a quick data export? Get it here!</span>
        <span @click="hideExportGenerator" style="position: absolute; right: 24px; cursor: pointer;"><img style="height: 24px;" :src="require('../../assets/close-outline.svg')"/></span>
      </div>
      <div v-if="!failed && !oldDashboardURL && allowAccess" id="container" style="width: 100%; height: 100%; margin: 0px auto; max-width:1350px;"></div>
      <div v-else-if="failed && !loaded" class="failedLoadingPage"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">Loading has failed. Try again here:</div><br><a-button style="margin-left:25%; margin-right:25%" onClick="window.location.reload();">Refresh Page</a-button></div></div>
      <div v-else-if="oldDashboardURL" class="failedLoadingPage"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">This Dashboard is not supported. <br>Admin is informed and will solve the problem soon.</div><br></div></div>
      <div v-else-if="!allowAccess" class="failedLoadingPage"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">You are not allowed to access this dashboard.</div><br></div></div>
    </a-row>
    <a-row v-if="pageOnFocus === 'singleOutletPage'" class="row" style="height:90vh;">
      <SingleOutletPageComponent @go-back="toggleSingleOutletPage" :projectId="$route.params.projectId" :dashboardId="$route.params.dashboardId" :outletId="singleOutletPageId" :isComponent=true />
    </a-row>
  </div>
</template>

<script>
import SingleOutletPageComponent from './SingleOutletPageComponent'
import firebase from 'firebase'
import { auth, db } from '@/firebase'
import axios from 'axios'
import * as pbi from 'powerbi-client'
import { path } from 'ramda'
var CryptoJS = require('crypto-js')

export default {
  name: 'PowerbiPage',
  data () {
    return {
      hideIntercom: window.innerWidth <= 1350,
      overlay: false,
      loaded: false,
      failed: false,
      allowAccess: true,
      oldDashboardURL: false,
      hidePageNavigation: false,
      reportId: '',
      groupId: '',
      embedToken: '',
      url: '',
      projectname: '',
      dashboardname: '',
      dashmoteUser: false,
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      modalTitle: '',
      embeddedReport: '', // For future release
      filterData: '', // For future release
      previousFilters: '',
      singleOutletPageEnabled: false,
      pageOnFocus: 'PowerBI',
      singleOutletPageId: '',
      showExportGenerator: false
    }
  },
  components: {
    SingleOutletPageComponent
  },
  methods: {
    goMainPage () {
      this.$router.push('/')
    },
    toggleSingleOutletPage () {
      this.pageOnFocus = 'PowerBI'
      this.getDashboard()
    },
    // extract powebi dashboard data
    getDashboard () {
      const self = this
      const permissions = pbi.models.Permissions.All
      // FOR HIDING NAVIGATION -> background: panes: {
      //      pageNavigation: {
      //        visible: false
      //      }
      //    }
      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: this.embedToken,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${this.reportId}&groupId=${this.groupId}`,
        id: this.reportId,
        permissions: permissions,
        settings: {
          filterPaneEnabled: true,
          navContentPaneEnabled: true,
          panes: {
            pageNavigation: {
              visible: !this.hidePageNavigation
            }
          }
        }
      }

      const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)
      const reportContainer = document.getElementById('container')
      const report = powerbi.embed(reportContainer, config)
      self.embeddedReport = report // For future release

      report.on('pageChanged', function (event) {
        self.setPageChanged(event, report)
      })

      report.on('dataSelected', function (event) {
        self.setDataSelected(event)
        // console.log(event.detail)
      })

      report.on('loaded', function (e) {
        self.loaded = true
        self.$Progress.finish()
        // console.log(e)
        // console.log('this id is' + this.getReportId())
      })

      report.on('buttonClicked', function (event) {
        self.trackClearFilters(event)
        self.trackChangeTableList(event)
        // console.log(event)
      })

      report.on('rendered', function (e) {
        self.loaded = true
        self.$Progress.finish()
        self.getPageFilters(report)
        report.getFilters()
          .then(filters => {
            if (filters.length > 0) {
              // console.log(filters)
              self.trackFilters(filters)
            }
          })
      })
    },

    async getPageFilters (report) {
      const pages = await report.getPages()

      // Retrieve active page
      const activePage = pages.filter(function (page) {
        return page.isActive
      })[0]

      // console.log(activePage)
      activePage.getFilters()
        .then(filters => {
          if (activePage.displayName !== 'DSF Explorer' && activePage.displayName !== 'Map Explorer') {
            // console.log(filters)
            this.trackFilters(filters)
          }
        })
    },

    getData () {
      const self = this
      db.ref(`projects/${self.$route.params.projectId}`).once('value', snapshot => {
        self.projectname = snapshot.val().name
      })
      db.ref(`projects/${self.$route.params.projectId}/dashboards/${self.$route.params.dashboardId}`).once('value', snapshot => {
        self.url = snapshot.val().url
        if (snapshot.val().hidePageNavigation) {
          self.hidePageNavigation = snapshot.val().hidePageNavigation
        }
        if (snapshot.val().hasExportGenerator) {
          self.showExportGenerator = snapshot.val().hasExportGenerator
        }
        self.dashboardname = snapshot.val().name
        if (snapshot.val().singleOutletPage) {
          self.singleOutletPageEnabled = snapshot.val().singleOutletPage
        }
        self.getAccessToken()
      })
    },
    getAccessToken () {
      axios.get(`${process.env.VUE_APP_POWERBI_GET_ACCESS_TOKEN}`).then(response => {
        this.getEmbedToken(response.data)
      })
    },
    getReportId () {
      const args = this.url.split('/')
      if (args[5] === 'reports') {
        this.reportId = args[6]
        return args[6]
      }
    },
    getGroupId () {
      const args = this.url.split('/')
      if (args[3] === 'groups') {
        this.groupId = args[4]
        return args[4]
      }
    },
    getEmbedToken (accessToken) {
      const reportId = this.getReportId()
      const groupId = this.getGroupId()
      // console.log(reportId + groupId)
      if (!reportId || !groupId) {
        console.log('This Dashboard is not supported')
        this.oldDashboardURL = true
      }

      axios.get(`${process.env.VUE_APP_POWERBI_GET_EMBED_TOKEN}?access_token=${accessToken}&report_id=${reportId}&group_id=${groupId}`).then(response => {
        this.embedToken = response.data
        this.getDashboard()
      })
    },
    trackClearFilters (event) {
      if (event.detail.title.includes('Btn_ClearFilters')) {
        this.$mixpanel.track('Clear Filters button pressed', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Clear Filters', { app: 'forge' })
      }
    },
    trackChangeTableList (event) {
      if (event.detail.title === 'OutExp_Btn_ListOutletsPortfolio') {
        this.$mixpanel.track('Switch Table type', { app: 'forge', tableType: 'Outlet explorer - List of Outlets w/ Portfolio' })
        this.$intercom.trackEvent('trackEvent', 'Switch Table type', { app: 'forge', tableType: 'Outlet explorer - List of Outlets w/ Portfolio' })
      }
      if (event.detail.title === 'OutExp_Btn_ListOutlets') {
        this.$mixpanel.track('Switch Table type', { app: 'forge', tableType: 'Outlet explorer - List of Outlets' })
        this.$intercom.trackEvent('trackEvent', 'Switch Table type', { app: 'forge', tableType: 'Outlet explorer - List of Outlets' })
      }
      if (event.detail.title === 'BevExp_Btn_ListBevs') {
        this.$mixpanel.track('Switch Table type', { app: 'forge', tableType: 'Beverage explorer - List of Beverages' })
        this.$intercom.trackEvent('trackEvent', 'Switch Table type', { app: 'forge', tableType: 'Beverage explorer - List of Beverages' })
      }
      if (event.detail.title === 'BevExp_Btn_ListOutlets') {
        this.$mixpanel.track('Switch Table type', { app: 'forge', tableType: 'Beverage explorer - List of Outlets' })
        this.$intercom.trackEvent('trackEvent', 'Switch Table type', { app: 'forge', tableType: 'Beverage explorer - List of Outlets' })
      }
    },
    trackFilters (filters) {
      var filter
      var filtersToTrack = []
      var filterName = []
      var filtervalue = []
      var noDefaultValueInFilters = false
      for (filter in filters) {
        // console.log(filters[filter])
        if (typeof filters[filter].values !== 'undefined') {
          if (filters[filter].values.length > 0) {
            filtersToTrack.push(filters[filter].target.column + ': ' + filters[filter].values[0])
            filterName.push(filters[filter].displaySettings.displayName)
            filtervalue.push(filters[filter].values[0])
            noDefaultValueInFilters = true
          }
        }
      }
      // console.log(Object.assign({}, filtersToTrack))
      // console.log(filtersToTrack)
      if (noDefaultValueInFilters) {
        // Check if the filters changed since last tracking.
        if (!this.previousFilters) {
          this.$mixpanel.track('Changed Filters', Object.assign({}, filtersToTrack))
          for (filter in filterName) {
            this.$mixpanel.track('Filters Name', { name: filterName[filter], value: filtervalue[filter] })
          }
          this.previousFilters = filtersToTrack
        } else {
          if (this.arrayEquals(this.previousFilters, filtersToTrack)) {
            // Do nothing
          } else { // Object.assign({}, filtersToTrack)
            this.$mixpanel.track('Changed Filters', Object.assign({}, filtersToTrack))
            for (filter in filterName) {
              this.$mixpanel.track('Filters Name', { name: filterName[filter], value: filtervalue[filter] })
            }
            this.previousFilters = filtersToTrack
          }
        }
      }
    },
    arrayEquals (a, b) {
      return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    },
    setDataSelected (event) {
      const pagenames = ['ONS - Overview', 'ONS - KH', 'ONS - MY', 'ONS - PH', 'ONS - SG', 'ONS - VN', 'ONS - TH', 'ONS - ID', 'ONS - AU', 'ONS - NZ']
      if (event.detail.visual.type === 'slicer') {
        const selectorChange = event.detail.visual.title + ' set to: ' + event.detail.dataPoints[0].identity[0].equals
        if (pagenames.indexOf(event.detail.page.displayName) === -1) {
          this.$mixpanel.track('AND/OR Selector setting changed', { app: 'forge', change: selectorChange })
          this.$intercom.trackEvent('trackEvent', 'AND/OR Selector setting changed', { app: 'forge', change: selectorChange })
        }
      }
      if (event.detail.page.displayName === 'Beverage Explorer') {
        if (event.detail.visual.type === 'columnChart') {
          this.$mixpanel.track('Clicked columnChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked columnChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'clusteredBarChart') {
          this.$mixpanel.track('Clicked clusteredBarChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked clusteredBarChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'donutChart') {
          this.$mixpanel.track('Clicked donutChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked donutChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'areaChart') {
          this.$mixpanel.track('Clicked areaChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked areaChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'pivotTable') {
          this.$mixpanel.track('Clicked pivotTable - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked pivotTable - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'tableEx') {
          this.$mixpanel.track('Clicked Table - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked Table - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedValue: event.detail.dataPoints[0].identity[0].equals, dashboardName: event.detail.page.displayName })
          // Single Outlet Page Routing
          if (this.singleOutletPageEnabled) {
            // Implementation of filter for Objects
            Object.filter = (obj, predicate) =>
              Object.assign(...Object.keys(obj)
                .filter(key => predicate(obj[key]))
                .map(key => ({ [key]: obj[key] })))

            // Get the dataPoints identity of where target column is Outlet Dashmote Id
            const dataPointObject = Object.filter(event.detail.dataPoints[0].identity, score => score.target.column === 'Outlet Dashmote Id')
            // Get the dashmoteId by going deeper into the structure and getting equals
            const dashmoteId = Object.values(dataPointObject)[0].equals
            this.goToSingleOutletView(dashmoteId)
          }
        }
        if (event.detail.visual.type === 'PBI_CV_EB3A4088_75C5_4746_9D8B_255A7B7ECD6C') {
          this.$mixpanel.track('Clicked mapChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
          this.$intercom.trackEvent('trackEvent', 'Clicked mapChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
        }
      }
      if (event.detail.page.displayName === 'DSF Explorer') {
        if (event.detail.visual.type === 'columnChart') {
          this.$mixpanel.track('Clicked columnChart - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked columnChart - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'clusteredBarChart') {
          this.$mixpanel.track('Clicked clusteredBarChart - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked clusteredBarChart - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'pivotTable') {
          this.$mixpanel.track('Clicked pivotTable - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked pivotTable - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'donutChart') {
          this.$mixpanel.track('Clicked donutChart - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked donutChart - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'PBI_CV_EB3A4088_75C5_4746_9D8B_255A7B7ECD6C') {
          this.$mixpanel.track('Clicked mapChart - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
          this.$intercom.trackEvent('trackEvent', 'Clicked mapChart - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
        }
        if (event.detail.visual.type === 'tableEx') {
          this.$mixpanel.track('Clicked Table - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked Table - DSF Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          // Single Outlet Page Routing
          if (this.singleOutletPageEnabled) {
            // Implementation of filter for Objects
            Object.filter = (obj, predicate) =>
              Object.assign(...Object.keys(obj)
                .filter(key => predicate(obj[key]))
                .map(key => ({ [key]: obj[key] })))

            // Get the dataPoints identity of where target column is Outlet Dashmote Id
            const dataPointObject = Object.filter(event.detail.dataPoints[0].identity, score => score.target.column === 'Outlet Dashmote Id')
            // Get the dashmoteId by going deeper into the structure and getting equals
            const dashmoteId = Object.values(dataPointObject)[0].equals
            this.goToSingleOutletView(dashmoteId)
          }
        }
      }
      if (event.detail.page.displayName === 'Franchise Explorer') {
        if (event.detail.visual.type === 'columnChart') {
          this.$mixpanel.track('Clicked columnChart - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked columnChart - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'clusteredBarChart') {
          this.$mixpanel.track('Clicked clusteredBarChart - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked clusteredBarChart - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'donutChart') {
          this.$mixpanel.track('Clicked donutChart - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked donutChart - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'areaChart') {
          this.$mixpanel.track('Clicked areaChart - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked areaChart - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'pivotTable') {
          this.$mixpanel.track('Clicked pivotTable - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked pivotTable - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'tableEx') {
          this.$mixpanel.track('Clicked Table - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked Table - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          // Single Outlet Page Routing
          if (this.singleOutletPageEnabled) {
            // Implementation of filter for Objects
            Object.filter = (obj, predicate) =>
              Object.assign(...Object.keys(obj)
                .filter(key => predicate(obj[key]))
                .map(key => ({ [key]: obj[key] })))

            // Get the dataPoints identity of where target column is Outlet Dashmote Id
            const dataPointObject = Object.filter(event.detail.dataPoints[0].identity, score => score.target.column === 'Outlet Dashmote Id')
            // Get the dashmoteId by going deeper into the structure and getting equals
            const dashmoteId = Object.values(dataPointObject)[0].equals
            this.goToSingleOutletView(dashmoteId)
          }
        }
        if (event.detail.visual.type === 'PBI_CV_EB3A4088_75C5_4746_9D8B_255A7B7ECD6C') {
          this.$mixpanel.track('Clicked mapChart - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
          this.$intercom.trackEvent('trackEvent', 'Clicked mapChart - Franchise Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
        }
      }
      if (event.detail.page.displayName === 'Snack Explorer') {
        if (event.detail.visual.type === 'columnChart') {
          this.$mixpanel.track('Clicked columnChart - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked columnChart - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'clusteredBarChart') {
          this.$mixpanel.track('Clicked clusteredBarChart - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked clusteredBarChart - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'donutChart') {
          this.$mixpanel.track('Clicked donutChart - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked donutChart - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'areaChart') {
          this.$mixpanel.track('Clicked areaChart - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked areaChart - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'pivotTable') {
          this.$mixpanel.track('Clicked pivotTable - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked pivotTable - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'tableEx') {
          this.$mixpanel.track('Clicked Table - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked Table - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          // Single Outlet Page Routing
          if (this.singleOutletPageEnabled) {
            // Implementation of filter for Objects
            Object.filter = (obj, predicate) =>
              Object.assign(...Object.keys(obj)
                .filter(key => predicate(obj[key]))
                .map(key => ({ [key]: obj[key] })))

            // Get the dataPoints identity of where target column is Outlet Dashmote Id
            const dataPointObject = Object.filter(event.detail.dataPoints[0].identity, score => score.target.column === 'Outlet Dashmote Id')
            // Get the dashmoteId by going deeper into the structure and getting equals
            const dashmoteId = Object.values(dataPointObject)[0].equals
            this.goToSingleOutletView(dashmoteId)
          }
        }
        if (event.detail.visual.type === 'PBI_CV_EB3A4088_75C5_4746_9D8B_255A7B7ECD6C') {
          this.$mixpanel.track('Clicked mapChart - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
          this.$intercom.trackEvent('trackEvent', 'Clicked mapChart - Snack Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
        }
      }
      if (event.detail.page.displayName === 'Outlet Explorer') {
        if (event.detail.visual.type === 'columnChart') {
          this.$mixpanel.track('Clicked columnChart - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked columnChart - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'clusteredBarChart') {
          this.$mixpanel.track('Clicked clusteredBarChart - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked clusteredBarChart - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'pivotTable') {
          this.$mixpanel.track('Clicked pivotTable - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked pivotTable - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'donutChart') {
          this.$mixpanel.track('Clicked donutChart - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked donutChart - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'PBI_CV_EB3A4088_75C5_4746_9D8B_255A7B7ECD6C') {
          this.$mixpanel.track('Clicked mapChart - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
          this.$intercom.trackEvent('trackEvent', 'Clicked mapChart - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
        }
        if (event.detail.visual.type === 'tableEx') {
          this.$mixpanel.track('Clicked Table - Outlet Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked Table - Outlet Explore', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          // Single Outlet Page Routing
          if (this.singleOutletPageEnabled) {
            // Implementation of filter for Objects
            Object.filter = (obj, predicate) =>
              Object.assign(...Object.keys(obj)
                .filter(key => predicate(obj[key]))
                .map(key => ({ [key]: obj[key] })))

            // Get the dataPoints identity of where target column is Outlet Dashmote Id
            const dataPointObject = Object.filter(event.detail.dataPoints[0].identity, score => score.target.column === 'Outlet Dashmote Id')
            // Get the dashmoteId by going deeper into the structure and getting equals
            const dashmoteId = Object.values(dataPointObject)[0].equals
            this.goToSingleOutletView(dashmoteId)
          }
        }
      }
      if (event.detail.page.displayName === 'Map Explorer') {
        if (event.detail.visual.type === 'PBI_CV_EB3A4088_75C5_4746_9D8B_255A7B7ECD6C') {
          this.$mixpanel.track('Clicked mapChart - Map Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
          this.$intercom.trackEvent('trackEvent', 'Clicked mapChart - Map Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
        }
        if (event.detail.visual.type === 'tableEx') {
          this.$mixpanel.track('Clicked Table - Map Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked Table - Map Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          // Single Outlet Page Routing
          if (this.singleOutletPageEnabled) {
            // Implementation of filter for Objects
            Object.filter = (obj, predicate) =>
              Object.assign(...Object.keys(obj)
                .filter(key => predicate(obj[key]))
                .map(key => ({ [key]: obj[key] })))

            // Get the dataPoints identity of where target column is Outlet Dashmote Id
            const dataPointObject = Object.filter(event.detail.dataPoints[0].identity, score => score.target.column === 'Outlet Dashmote Id')
            // Get the dashmoteId by going deeper into the structure and getting equals
            const dashmoteId = Object.values(dataPointObject)[0].equals
            this.goToSingleOutletView(dashmoteId)
          }
        }
      }
      if (event.detail.page.displayName === 'Brand Battle') {
        if (event.detail.visual.type === 'columnChart') {
          this.$mixpanel.track('Clicked Chart - Brand Battle', { app: 'forge' })
          this.$intercom.trackEvent('trackEvent', 'Clicked Chart - Brand Battle', { app: 'forge' })
        }
        if (event.detail.visual.type === 'tableEx') {
          this.$mixpanel.track('Clicked Table - Brand Battle', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked Table - Brand Battle', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          // Single Outlet Page Routing
          if (this.singleOutletPageEnabled) {
            // Implementation of filter for Objects
            Object.filter = (obj, predicate) =>
              Object.assign(...Object.keys(obj)
                .filter(key => predicate(obj[key]))
                .map(key => ({ [key]: obj[key] })))

            // Get the dataPoints identity of where target column is Outlet Dashmote Id
            const dataPointObject = Object.filter(event.detail.dataPoints[0].identity, score => score.target.column === 'Outlet Dashmote Id')
            // Get the dashmoteId by going deeper into the structure and getting equals
            const dashmoteId = Object.values(dataPointObject)[0].equals
            this.goToSingleOutletView(dashmoteId)
          }
        }
      } // new plot
      if (pagenames.indexOf(event.detail.page.displayName) !== -1) {
        if (event.detail.visual.type === 'slicer') {
          const selectchange = event.detail.visual.title + ' set to: ' + event.detail.dataPoints[0].identity[0].equals
          this.$mixpanel.track('Data Refresh Date changed', { app: 'forge', change: selectchange })
          this.$intercom.trackEvent('trackEvent', 'Data Refresh Date changed', { app: 'forge', change: selectchange })
        }
        if (event.detail.visual.type === 'columnChart') {
          this.$mixpanel.track('Clicked columnChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked columnChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'clusteredBarChart') {
          this.$mixpanel.track('Clicked clusteredBarChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked clusteredBarChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'donutChart') {
          this.$mixpanel.track('Clicked donutChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked donutChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'areaChart') {
          this.$mixpanel.track('Clicked areaChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked areaChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'pivotTable') {
          this.$mixpanel.track('Clicked pivotTable - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked pivotTable - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].values[0].target.table, column: event.detail.dataPoints[0].values[0].target.column, clickedValue: event.detail.dataPoints[0].values[0].value, dashboardName: event.detail.page.displayName })
        }
        if (event.detail.visual.type === 'tableEx') {
          this.$mixpanel.track('Clicked Table - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          this.$intercom.trackEvent('trackEvent', 'Clicked Table - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: event.detail.dataPoints[0].identity[0].target.column, clickedRow: event.detail.dataPoints[0].identity, dashboardName: event.detail.page.displayName })
          // Single Outlet Page Routing
          if (this.singleOutletPageEnabled) {
            // Implementation of filter for Objects
            Object.filter = (obj, predicate) =>
              Object.assign(...Object.keys(obj)
                .filter(key => predicate(obj[key]))
                .map(key => ({ [key]: obj[key] })))

            // Get the dataPoints identity of where target column is Outlet Dashmote Id
            const dataPointObject = Object.filter(event.detail.dataPoints[0].identity, score => score.target.column === 'Outlet Dashmote Id')
            // Get the dashmoteId by going deeper into the structure and getting equals
            const dashmoteId = Object.values(dataPointObject)[0].equals
            this.goToSingleOutletView(dashmoteId)
          }
        }
        if (event.detail.visual.type === 'PBI_CV_EB3A4088_75C5_4746_9D8B_255A7B7ECD6C') {
          this.$mixpanel.track('Clicked mapChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
          this.$intercom.trackEvent('trackEvent', 'Clicked mapChart - Beverage Explorer', { app: 'forge', table: event.detail.dataPoints[0].identity[0].target.table, column: 'Lat / Long' })
        }
      }
    },
    goToExportGenerator () {
      if (this.dashboardname && this.projectname) {
        window.open(`https://dashmote.typeform.com/to/ziwMHNok#dashboard_name=${this.dashboardname.split(' ').join('_')}&folder_name=${this.projectname.split(' ').join('_')}`, '_blank')
      } else {
        this.$message.error('Error: Please wait until the dashboard finished loading')
      }
    },
    hideExportGenerator () {
      this.showExportGenerator = false
    },
    goToSingleOutletView (dashmoteOutletId) {
      // const routeData = this.$router.resolve({ path: `/outlet/${this.$route.params.projectId}/${this.$route.params.dashboardId}/${dashmoteOutletId}` })
      // window.open(routeData.href, '_blank')

      this.singleOutletPageId = dashmoteOutletId
      this.pageOnFocus = 'singleOutletPage'
      // this.$router.push(`/outlet/${this.$route.params.projectId}/${this.$route.params.dashboardId}/${dashmoteOutletId}`)

      // Tracking
      this.$mixpanel.track('Go to new Single Outlet Page', { projectId: this.$route.params.projectId, dashboardId: this.$route.params.dashboardId, outletId: dashmoteOutletId })
      this.$intercom.trackEvent('trackEvent', 'Go to new Single Outlet Page', { projectId: this.$route.params.projectId, dashboardId: this.$route.params.dashboardId, outletId: dashmoteOutletId })
    },
    showModal () {
      this.visible = true
    },
    handleOk (e) {
      this.ModalText = 'The modal will be closed after two seconds'
      this.confirmLoading = true
      setTimeout(() => {
        this.visible = false
        this.confirmLoading = false
      }, 2000)
    },
    handleCancel (e) {
      // console.log('Clicked cancel button')
      this.visible = false
    },
    setPageChanged (event, report) {
      if (event.detail.newPage.displayName === 'Homepage') {
        this.$mixpanel.track('Go to Home Screen', { app: 'forge', dashboardName: this.dashboardname })
        this.$intercom.trackEvent('trackEvent', 'Go to Home Screen', { app: 'forge', dashboardName: this.dashboardname })
      }
      if (event.detail.newPage.displayName === 'Beverage Explorer') {
        this.$mixpanel.track('Go to Beverage Explorer', { app: 'forge', dashboardName: this.dashboardname })
        this.$intercom.trackEvent('trackEvent', 'Go to Beverage Explorer', { app: 'forge', dashboardName: this.dashboardname })
      }
      if (event.detail.newPage.displayName === 'Outlet Explorer') {
        this.$mixpanel.track('Go to Outlet Explorer', { app: 'forge', dashboardName: this.dashboardname })
        this.$intercom.trackEvent('trackEvent', 'Go to Outlet Explorer', { app: 'forge', dashboardName: this.dashboardname })
      }
      if (event.detail.newPage.displayName === 'Map Explorer') {
        this.$mixpanel.track('Go to Map Explorer', { app: 'forge', dashboardName: this.dashboardname })
        this.$intercom.trackEvent('trackEvent', 'Go to Map Explorer', { app: 'forge', dashboardName: this.dashboardname })
      }
      if (event.detail.newPage.displayName === 'Brand Battle') {
        this.$mixpanel.track('Go to Brand Battle', { app: 'forge', dashboardName: this.dashboardname })
        this.$intercom.trackEvent('trackEvent', 'Go to Brand Battle', { app: 'forge', dashboardName: this.dashboardname })
      }
      if (event.detail.newPage.displayName === 'ONS - Overview') {
        this.$mixpanel.track('Go to ONS Overview', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Go to ONS Overview', { app: 'forge' })
      }
      if (event.detail.newPage.displayName === 'ONS - KH') {
        this.$mixpanel.track('Go to ONS - KH', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Go to ONS - KH', { app: 'forge' })
      }
      if (event.detail.newPage.displayName === 'ONS - MY') {
        this.$mixpanel.track('Go to ONS - MY', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Go to ONS - MY', { app: 'forge' })
      }
      if (event.detail.newPage.displayName === 'ONS - PH') {
        this.$mixpanel.track('Go to ONS - PH', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Go to ONS - PH', { app: 'forge' })
      }
      if (event.detail.newPage.displayName === 'ONS - SG') {
        this.$mixpanel.track('Go to ONS - SG', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Go to ONS - SG', { app: 'forge' })
      }
      if (event.detail.newPage.displayName === 'ONS - VN') {
        this.$mixpanel.track('Go to ONS - VN', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Go to ONS - VN', { app: 'forge' })
      }
      if (event.detail.newPage.displayName === 'ONS - TH') {
        this.$mixpanel.track('Go to ONS - TH', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Go to ONS - TH', { app: 'forge' })
      }
      if (event.detail.newPage.displayName === 'ONS - ID') {
        this.$mixpanel.track('Go to ONS - ID', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Go to ONS - ID', { app: 'forge' })
      }
      if (event.detail.newPage.displayName === 'ONS - AU') {
        this.$mixpanel.track('Go to ONS - AU', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Go to ONS - AU', { app: 'forge' })
      }
      if (event.detail.newPage.displayName === 'ONS - NZ') {
        this.$mixpanel.track('Go to ONS - NZ', { app: 'forge' })
        this.$intercom.trackEvent('trackEvent', 'Go to ONS - NZ', { app: 'forge' })
      }
      if (event.detail.newPage.displayName === 'Single Outlet View') {
        report.getPages()
          .then(pages => {
            pages[4].getFilters()
              .then(filters => {
                this.$mixpanel.track('Go to Old Single Outlet View Page', { outletId: filters[13].values[0] })
                this.$intercom.trackEvent('trackEvent', 'Go to Old Single Outlet View Page', { outletId: filters[13].values[0] })
              })
          })
      }
      this.$Progress.start()
    },
    setFailed () {
      if (!this.loaded && !this.oldDashboardURL && this.allowAccess) {
        this.failed = true
        // this.loaded = true
        this.$Progress.finish()
      }
    },
    checkPermission() {
    const self = this;

    auth.onAuthStateChanged((user) => {
      if (user) {
        const userEmail = user.email;

        const getUserByEmail = firebase.functions().httpsCallable('getUserByEmail');
        getUserByEmail(userEmail)
          .then((response) => {
            db.ref(`users/${path(['data', 'uid'], response)}/projects/${self.$route.params.projectId}/`)
              .once('value', (snapshot) => {
                self.allowAccess = !!snapshot.val() || self.dashmoteUser;
              });
          })
          .catch((error) => {
            console.error('Error checking permission:', error);
            self.allowAccess = false;
          });
      } else {
        console.error('No user is currently logged in.');
        self.allowAccess = false;
      }
    });
  },
  checkIsDashmoteUser() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const emailPostfix = user.email.split('@')[1];
        this.dashmoteUser = emailPostfix === 'dashmote.com';
      } else {
        console.error('No user is currently logged in.');
        this.dashmoteUser = false;
      }
    });
  },
    // Function for a future release
    getFilters () {
      const self = this
      this.embeddedReport.getFilters()
        .then(filters => {
          self.filterData = filters
        })
    },
    // Functions for a future release
    setFilters () {
      this.embeddedReport.setFilters(this.filterData)
        .catch(errors => {
          // Handle error
        })
    }
  },
  mounted () {
    this.checkIsDashmoteUser()
    this.checkPermission()
    this.$Progress.start()
    this.getData()
    setTimeout(this.setFailed, 60000) // 60 seconds timout should be very safe.
  },
  created () {
    if (innerWidth <= 1350) {
      window.Intercom('update', {
        hide_default_launcher: true
      })
    }

    addEventListener('resize', () => {
      this.hideIntercom = innerWidth <= 1350
      if (this.hideIntercom) {
        window.Intercom('update', {
          hide_default_launcher: true
        })
      } else {
        window.Intercom('update', {
          hide_default_launcher: false
        })
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../Projects/Projects.css';
@import './DetailPage.css';
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.loader {
  z-index: 10;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #223273; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
