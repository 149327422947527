<template>
    <div v-if="!mobile()">
        <a-row class="dashboard-subtitle-row detail-content">
            <img :src="require('../../assets/icon_grid_filled.svg')" style="vertical-align: -8px; margin-right: 16px; margin-left: 26px; margin-top: 21px;" alt="">
            <span style="color: #373B53;" class="header-4">{{ name }}</span>
            <a-input-search placeholder="Search for a user name" style="width: 270px; float: right;line-height: 66px;margin-right: 20px;" @change="onSearch" />
        </a-row>
        <div v-if="!allowAccess" class="failedLoadingPage" style="margin-top:5%"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">You are not allowed to access this project.</div><br></div></div>
        <a-row v-else class="row-space-8" style="height: 520px;">
            <UsersTable
                :data="filterTableData"
                :columns="columns"
            />
        </a-row>
    </div>

    <div v-else>
        <a-row class="dashboard-subtitle-row detail-content">
           <img :src="require('../../assets/icon_grid_filled.svg')" style="vertical-align: -8px; margin-left: 26px; margin-right: 16px; margin-top: 21px;" alt="">
            <span style="color: #373B53;" class="header-4">{{ name }}</span>
            <a-input-search placeholder="Search for a user name" style="width: 250px; float: right;line-height: 66px; margin-left: 20px; margin-right: 20px;" @change="onSearch" />
        </a-row>
        <div v-if="!allowAccess" class="failedLoadingPage" style="margin-top:5%"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">You are not allowed to access this project.</div><br></div></div>
        <a-row v-else class="row-space-8 mobile-users-card-area" style="height: 520px; overflow-y: scroll;">
            <UsersTableMobile
                :data="filterTableData"
                :columns="mobileColumns"
            />
        </a-row>
    </div>
</template>
<script>
import { auth, db } from '../../firebase'; // Import Firebase auth and db
import UsersTable from './UsersTable.vue';
import UsersTableMobile from './UsersTableMobile.vue';
import { values, keys, path as RPath } from 'ramda';
import moment from 'moment';
const R = require('ramda');
const mobile = require('is-mobile');

const columns = [
  {
    title: 'Full name',
    dataIndex: 'displayName',
    width: '40%',
    scopedSlots: { customRender: 'column1' },
    ellipsis: 'true'
  },
  {
    title: 'User Name',
    dataIndex: 'email',
    width: '30%',
    scopedSlots: { customRender: 'column2' },
    ellipsis: 'true'
  },
  {
    title: 'Last Seen',
    dataIndex: 'lastSeen',
    scopedSlots: { customRender: 'column3' },
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.lastSeen && b.lastSeen) {
        return moment(a.lastSeen).unix() - moment(b.lastSeen).unix();
      } else if (!a.lastSeen) {
        return -1;
      } else if (!b.lastSeen) {
        return 1;
      } else {
        return 0;
      }
    }
  }
];

const mobileColumns = [
  {
    title: 'Full name',
    dataIndex: 'displayName',
    width: '40%',
    scopedSlots: { customRender: 'column1' }
  }
];

export default {
  name: 'Users',
  components: {
    UsersTable,
    UsersTableMobile
  },
  data() {
    return {
      mobile,
      cardData: Array(5).fill({ displayName: 'Loading', email: 'Loading', createdAt: 'Loading' }),
      columns,
      mobileColumns,
      search: '',
      name: '',
      usersList: [],
      allowAccess: true,
      dashmoteUser: false,
      user: null
    };
  },
  methods: {
    onSearch(e) {
      this.search = e.target.value;
    },
    async fetchCurrentUser() {
      return new Promise((resolve) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            this.user = user;
            resolve(user);
          } else {
            console.error('No user is currently logged in.');
            this.$router.push('/login');
            resolve(null);
          }
        });
      });
    },
    async checkPermission() {
      try {
        const user = this.user || (await this.fetchCurrentUser());
        if (!user) return;

        const userSnapshot = await db.ref(`users/${user.uid}/projects/${this.$route.params.id}`).once('value');
        this.allowAccess = !!userSnapshot.val() || this.dashmoteUser;
      } catch (error) {
        console.error('Error checking permissions:', error);
        this.allowAccess = false;
      }
    },
    async checkIsDashmoteUser() {
      try {
        const user = this.user || (await this.fetchCurrentUser());
        if (!user) return;

        this.dashmoteUser = user.email.endsWith('@dashmote.com');
      } catch (error) {
        console.error('Error checking Dashmote user:', error);
        this.dashmoteUser = false;
      }
    },
    async getData() {
      try {
        const snapshot = await db.ref(`projects/${this.$route.params.id}`).once('value');
        const projectData = snapshot.val();

        if (projectData?.name) {
          this.name = projectData.name;
        }
        if (projectData?.users) {
          this.usersList = keys(R.filter((item) => item === true, projectData.users));
        }

        const usersSnapshot = await db.ref('users/').once('value');
        const users = usersSnapshot.val();

        this.cardData = values(
          R.pickBy((item, key) => {
            if (this.usersList.includes(key)) {
              if (this.dashmoteUser) {
                return true;
              }
              return !item.email.endsWith('@dashmote.com');
            }
            return false;
          }, users)
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  },
  computed: {
    filterTableData() {
      return R.filter((item) => item.email.toLowerCase().includes(this.search.toLowerCase()), this.cardData);
    }
  },
  async created() {
    await this.checkIsDashmoteUser();
    await this.checkPermission();
    await this.getData();
  }
};
</script>
<style scoped>
@import './DetailPage.css';
@import '../Projects/Projects.css';
</style>
