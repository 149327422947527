<template>
<div>
  <a-row>
    <!-- For small screen sizes under 980px width / mobile -->
    <a-col v-if="this.smallscreen" class="login-left" :span="24">
      <a-row>
        <a-col :span="4"></a-col>
        <a-col
          :span="18"
          class="font-mobile login-width-mobile login-left-width"
        >
        <img class="login-logo-mobile" :src="require('../../assets/dashmote-logo-white.svg')" alt="">
          <div>
            <div class="header-1" style="margin-top: 75px; margin-bottom: 100px">
              Welcome to Dashmote!
            </div>
          </div>
          <a-row class="header-4">Please login to your account below.</a-row>
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item>
              <a-input style="padding:16px;"
                v-decorator="[
                  'email',
                  {
                    rules: [
                      {
                        type: 'email',
                        message: 'Email format wrong',
                      },
                      {
                        required: true,
                        message: 'Please input your Email!',
                      },
                    ],
                  },
                ]"
                placeholder="Email"
                ref="email"
                @keyup.enter="login"
              />
            </a-form-item>
            <a-form-item has-feedback>
              <a-input style="padding:16px;"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ],
                  },
                ]"
                type="password"
                placeholder="Password"
                ref="pwd"
                @keyup.enter="login"
              />
            </a-form-item>
          </a-form>
          <a-row>
            <span @click="goToForgotPassword()" style="color: #1990FF; cursor: pointer;" class="dash-text-button">Forgot your password?</span>
            <span @click="$intercom.show()" class="login-help-font">I need some help</span>
          </a-row>
          <a-button
            class="primary-button-style-total row-space-16"
            style="width: 100%; top: 16px; bottom: 16px; margin-bottom: 140px"
            @click="login"
            >Log In</a-button
          >
          <div class="copyright-text-mobile">© Dashmote {{ currentYear }}</div>
        </a-col>
        <a-col :span="2"></a-col>
      </a-row>
    </a-col>

    <!-- For normal screen sizes above 980px width / desktop -->
    <a-col v-else class="login-left" :span="12">
      <img class="login-logo" :src="require('../../assets/dashmote-logo.svg')" alt="">
      <a-row>
        <a-col :span="4"></a-col>
        <a-col
          :span="18"
          class="font-mobile login-width-mobile login-left-width"
        >
          <div>
            <div class="header-1 mobile-margin-top title-top">
              Welcome to Dashmote,
            </div>
            <div class="header-1 title-bottom">
              let's start your data journey!
            </div>
          </div>
          <a-row class="header-4">Please login to your account below.</a-row>
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item>
              <a-input style="padding:16px;"
                v-decorator="[
                  'email',
                  {
                    rules: [
                      {
                        type: 'email',
                        message: 'Email format wrong',
                      },
                      {
                        required: true,
                        message: 'Please input your Email!',
                      },
                    ],
                  },
                ]"
                placeholder="Email"
                ref="email"
                @keyup.enter="login"
              />
            </a-form-item>
            <a-form-item has-feedback>
              <a-input style="padding:16px;"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ],
                  },
                ]"
                type="password"
                placeholder="Password"
                ref="pwd"
                @keyup.enter="login"
              />
            </a-form-item>
          </a-form>
          <a-row>
            <span @click="goToForgotPassword()" style="color: #1990FF; cursor: pointer;" class="dash-text-button">Forgot your password?</span>
            <span @click="$intercom.show()" class="login-help-font">I need some help</span>
          </a-row>
          <a-button
            class="primary-button-style-total row-space-16 login-button"
            style="width: 100%; bottom: 16px;"
            @click="login"
            >Log In</a-button
          >
        </a-col>
        <a-col :span="2"></a-col>
      </a-row>
       <div class="copyright-text">© Dashmote {{ currentYear }}</div>
    </a-col>
    <!-- Right side of desktop login page -->
    <a-col class="login-right-block" :span="12">
            <div class="top-all-picture"></div>
      <div class="bottom-bgc"></div>
    </a-col>
  </a-row>
</div>

</template>
<script>
import { auth, GoogleAuthProvider } from '../../firebase'; // Import Firebase auth and GoogleAuthProvider
const mobile = require('is-mobile');

export default {
  name: 'Login',
  data() {
    return {
      mobile,
      smallscreen: window.innerWidth <= 980,
      email: '',
      currentYear: new Date().getFullYear()
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'register' });
  },
  created() {
    // Check if the client is using a mobile device or not, based on the screen width.
    addEventListener('resize', () => {
      this.smallscreen = innerWidth <= 980;
    });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
        }
      });
    },
    createTrackingProfile(email) {
      this.$mixpanel.identify(email);
      this.$mixpanel.people.set({
        $email: email // only reserved properties need the $
      });

      // Set trackingProfile status to true.
      localStorage.setItem('userTrackingProfileStatus', 'true');
      this.$store.commit('setUserTrackingProfileStatus', 'true');
    },
    goToForgotPassword() {
      this.$router.push('/forgotpassword');
    },
    errorMessage(errorText) {
      this.$message.error(errorText);
    },
    async login() {
      const email = this.$refs.email.stateValue;
      const pwd = this.$refs.pwd.stateValue;

      if (email) {
        if (email.indexOf('@dashmote.com') !== -1) {
          // Google login
          try {
            const result = await auth.signInWithPopup(new GoogleAuthProvider());
            const emailPostfix = result.user.email.split('@')[1];
            if (emailPostfix === 'dashmote.com') {
              localStorage.setItem('accessToken', result.credential.accessToken);
              localStorage.setItem('displayName', result.user.displayName);
              localStorage.setItem('userProfileImageUrl', result.user.photoURL);

              this.$store.commit('setToken', result.credential.accessToken);
              this.$store.commit('setDisplayName', result.user.displayName);
              this.$store.commit('setUserProfileImageUrl', result.user.photoURL);

              this.createTrackingProfile(result.user.email);
              this.$intercom.boot({
                name: result.user.displayName,
                email: result.user.email
              });
              this.$intercom.hide();
              this.$router.push('/');
            } else {
              await auth.signOut();
            }
          } catch (error) {
            console.error('Google login error:', error);
            this.$error({ title: 'Something went wrong, please try again!' });
          }
        } else {
          // Email and password login
          try {
            const result = await auth.signInWithEmailAndPassword(email, pwd);
            const token = await auth.currentUser.getIdToken();
            localStorage.setItem('accessToken', token);
            localStorage.setItem('displayName', result.user.displayName);
            localStorage.setItem('userProfileImageUrl', result.user.photoURL);

            this.$store.commit('setToken', token);
            this.$store.commit('setDisplayName', result.user.displayName);
            this.$store.commit('setUserProfileImageUrl', result.user.photoURL);

            this.createTrackingProfile(result.user.email);
            this.$intercom.boot({
              name: result.user.displayName,
              email: result.user.email
            });
            this.$intercom.hide();
            this.$router.push('/');
          } catch (error) {
            console.error('Email login error:', error);
            this.$error({ title: 'Username or password incorrect, please try again!' });
          }
        }
      }
    }
  }
};
</script>
<style scoped>
@import "./Login.css";
@import "./LoginMobile.css";
@import "../Projects/Projects.css";
@import "../Dashboards/DetailPage.css";
</style>
