<template>
  <div style="width:100%; margin-top:62px">
    <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
    <a-row class="row" style="height:90vh;" >
      <vue-progress-bar></vue-progress-bar>
      <iframe v-if="!failed && allowAccess" :src="url" id="container" style="display: flex; width: 100%; height: 100%; margin: 0px auto; max-width:1350px;"></iframe>
      <div v-else-if="failed" class="failedLoadingPage"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">Loading has failed. Try again here:</div><br><a-button style="margin-left:25%; margin-right:25%" onClick="window.location.reload();">Refresh Page</a-button></div></div>
      <div v-else-if="!allowAccess" class="failedLoadingPage"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">You are not allowed to access this dashboard.</div><br></div></div>
    </a-row>
  </div>
</template>

<script>
/* eslint-disable */
import { db } from '@/firebase';
import { auth } from '@/firebase'; // Import Firebase auth instance
import firebase from 'firebase';
import { path } from 'ramda';

export default {
  name: 'ColumboPage',
  data() {
    return {
      hideIntercom: window.innerWidth <= 1350,
      overlay: false,
      loaded: false,
      failed: false,
      allowAccess: true,
      url: '',
      dashmoteUser: false,
    };
  },
  methods: {
    getData() {
      const self = this;
      db.ref(`projects/${self.$route.params.projectId}/dashboards/${self.$route.params.dashboardId}`)
        .once('value', (snapshot) => {
          self.url = snapshot.val().url;
        });
    },
    setFailed() {
      if (!this.loaded && this.allowAccess) {
        this.failed = true;
        this.loaded = true;
        this.$Progress.finish();
      }
    },
    onReady(callback) {
      const intervalId = window.setInterval(() => {
        if (document.getElementById('ant-card-body') !== undefined) {
          window.clearInterval(intervalId);
          callback.call(this);
        }
      }, 11000);
    },
    sendMessage() {
      const ifr = document.getElementById('container');
      ifr.contentWindow.postMessage(
        JSON.stringify({
          email: auth.currentUser?.email || 'Unknown',
          projectId: this.$route.params.projectId,
          dashboardId: this.$route.params.dashboardId,
        }),
        '*'
      );
    },
    checkPermission() {
      const self = this;

      auth.onAuthStateChanged((user) => {
        if (user) {
          const userEmail = user.email;

          const getUserByEmail = firebase.functions().httpsCallable('getUserByEmail');
          getUserByEmail(userEmail)
            .then((response) => {
              db.ref(`users/${path(['data', 'uid'], response)}/projects/${self.$route.params.projectId}/`)
                .once('value', (snapshot) => {
                  self.allowAccess = !!snapshot.val() || self.dashmoteUser;
                });
            })
            .catch((error) => {
              console.error('Error checking permission:', error);
              self.allowAccess = false;
            });
        } else {
          console.error('No user is currently logged in.');
          self.allowAccess = false;
        }
      });
    },
    checkIsDashmoteUser() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          const emailPostfix = user.email.split('@')[1];
          this.dashmoteUser = emailPostfix === 'dashmote.com';
        } else {
          console.error('No user is currently logged in.');
          this.dashmoteUser = false;
        }
      });
    },
  },
  mounted() {
    this.checkIsDashmoteUser();
    this.checkPermission();
    this.$Progress.start();
    this.getData();

    const self = this;
    this.onReady(() => {
      self.loaded = true;
      self.$Progress.finish();
      self.sendMessage();
    });
    setTimeout(this.setFailed, 40000); // 40 seconds timeout should be very safe.
  },
  created() {
    if (innerWidth <= 1350) {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    }

    addEventListener('resize', () => {
      const iframe = document.getElementById('container');
      const elmnt = iframe?.contentWindow?.document.querySelector(
        '#app > div.__cov-progress'
      );
      if (elmnt) {
        elmnt.style.display = 'none';
      }

      this.hideIntercom = innerWidth <= 1350;
      if (this.hideIntercom) {
        window.Intercom('update', {
          hide_default_launcher: true,
        });
      } else {
        window.Intercom('update', {
          hide_default_launcher: false,
        });
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../Projects/Projects.css';
@import './DetailPage.css';
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
