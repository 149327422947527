<template>
<!-- header may never be shown on login page, even if there is an accestoken to prevent from showing it after using the back button -->
<div v-if="this.$route.path !== '/login' && this.$route.path !== '/forgotpassword' && this.$route.path !== '/resetpassword' "> <!-- Could also be replaced with an "You are already logged in" go to home header -->
    <img style="position: fixed; margin-left: 25px; margin-top: 24px; z-index:1000; cursor: pointer;" @click="toggleParentSideMenu()" @click.stop :src="this.menuIconSource" alt="">
    <div v-if="!mobile() && !headerHiddenMode" class="header">
        <a-col :span="1">
        </a-col>
        <a-col :span="6">
            <a v-if="!sidebarOpen" class="help" @click="highlightDashmoteUniversity" style="">Improve Your Dashboard Skills Here</a>
        </a-col>
        <a-col :span="4"></a-col>
        <a-col style="position: relative; display: flex; flex-direction: row; justify-content: flex-end; margin-right:10%;">
                <div style="position: relative; cursor: pointer; margin-left: 32px; color: #223273;" @click="goSetting()">
                        <span class="ant-dropdown-link header-3">
                        {{ displayName }}
                        </span>
                    <img style="margin-left: 8px; border-radius: 50%; height: 24px; width: 24px;" @error="imageError()" :src="profilePhotoURL" />
                </div>
        </a-col>
    </div>
    <!-- Header hidden mode -->
    <div v-else-if="!mobile() && headerHiddenMode" class="headerHidden">

    </div>
    <div v-else style="height: 60px;line-height: 60px;">
        <a-col :span="2"></a-col>
        <a-col :span="2">
            <img style="position: relative; z-index:1000" @click="toggleParentSideMenu()" @click.stop :src="this.menuIconSource" alt="">
        </a-col>
        <a-col :span="14"></a-col>
        <a-col :span="2"></a-col>
        <a-col :span="2">
            <img style="margin-left: 8px; border-radius: 50%; height: 24px; width: 24px;" @error="imageError()" :src="profilePhotoURL" @click="goSetting()" />
        </a-col>
        <a-col :span="2"></a-col>
    </div>
</div>
</template>
<script>
import { auth, db } from '../../../firebase'; // Import auth and db from firebase setup
const mobile = require('is-mobile');

export default {
  name: 'Header',
  data() {
    return {
      mobile,
      toggleDisable: false,
      headerHiddenMode: false,
      displayName: '',
      googlePhotoURL: require('../../../assets/icon_user.svg'),
      profilePhotoURL: require('../../../assets/icon_user.svg'),
      profilePhotoPlaceholder: require('../../../assets/icon_user.svg'),
      menuIconSource: require('../../../assets/menu-icon.svg'),
      sidebarOpen: false
    };
  },
  methods: {
    goNotifications() {
      this.$router.push('/notifications');
    },
    highlightDashmoteUniversity() {
      this.$emit('highlight-dashmote-university');
      this.toggleParentSideMenu();
    },
    toggleParentSideMenu() {
      if (!mobile) {
        if (this.menuIconSource === require('../../../assets/menu-icon.svg')) {
          this.menuIconSource = require('../../../assets/menu-icon-white.svg');
          this.sidebarOpen = true;
        } else {
          this.menuIconSource = require('../../../assets/menu-icon.svg');
          this.sidebarOpen = false;
        }
        this.$emit('toggle-side-menu');
      } else {
        if (!this.toggleDisable) {
          if (this.menuIconSource === require('../../../assets/menu-icon.svg')) {
            this.menuIconSource = require('../../../assets/menu-icon-white.svg');
            this.sidebarOpen = true;
          } else {
            this.menuIconSource = require('../../../assets/menu-icon.svg');
            this.sidebarOpen = false;
          }
          this.$emit('toggle-side-menu');
        }
        this.toggleDisable = true;
        setTimeout(() => {
          this.toggleDisable = false;
        }, 100);
      }
    },
    async logOut() {
      try {
        await auth.signOut(); // Sign out from Firebase

        // Clear localStorage items
        localStorage.removeItem('userEmail');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('displayName');
        localStorage.removeItem('userProfileImageUrl');
        localStorage.removeItem('userTrackingProfileStatus');
        localStorage.removeItem('userReference');

        // Redirect to login page
        this.$router.push('/login');
      } catch (error) {
        console.error('Error during logout:', error);
      }
    },
    goSetting() {
      // Track open account settings for Mixpanel
      this.$mixpanel.track('Open account settings');
      this.$router.push('/account');
    },
    goProjects() {
      this.$router.push('/');
    },
    imageError() {
      this.profilePhotoURL = this.profilePhotoPlaceholder;
    },
    updateUser(displayName, profilePhotoURL) {
      this.displayName = displayName;
      this.profilePhotoURL = profilePhotoURL;
    },
    hideHeader() {
      this.headerHiddenMode = true;
    },
    showHeader() {
      this.headerHiddenMode = false;
    },
    getProfilePhotoFromDB(uid) {
      db.ref('users/' + uid + '/').once('value', (snapshot) => {
        if (snapshot.val()?.photoURL) {
          this.profilePhotoURL = snapshot.val().photoURL;
        } else {
          this.profilePhotoURL = this.googlePhotoURL;
        }
      });
    },
    getUser() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.displayName = user.displayName || 'User'; // Fallback if displayName is null
          this.googlePhotoURL = user.photoURL || this.profilePhotoPlaceholder;
          this.getProfilePhotoFromDB(user.uid); // Fetch custom profile photo from DB
        } else {
          console.error('No user is currently logged in.');
        }
      });
    }
  },
  created() {
    // Get the latest user data on component creation
    this.getUser();
  }
};
</script>
<style scoped>
@import './header.css';
@import '../../../pages/Projects/Projects.css';
</style>
