<template>
  <div class="project-wrapper" v-if="!mobile()">
    <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
    <a-row>
      <div class="header-2" style="margin-top: 38px">My Workspaces</div>
      <p class="paragraph-large" style="margin-bottom: 60px">
        Here you can find your workspaces and dashboards inside.
      </p>
    </a-row>
    <a-row>
      <a-col>
        <div class="project-search-style">
        <a-row class="search-style" style="margin-bottom: 20px">
          <span class="header-3">Sort workspaces by:</span>
          <span class="project-sort-button" :class="{activeAscending: this.sortType === 'nameAsc', activeDescending: this.sortType === 'nameDsc'}" v-on:click="onSort('name')"><span class="button-text">Name</span><img class="sortingArrow" style="margin-left: 8px; width: 12px;"></span>
          <span class="project-sort-button" :class="{activeAscending: this.sortType === 'dateAsc', activeDescending: this.sortType === 'dateDsc'}" v-on:click="onSort('date')"><span class="button-text">Date added</span><img class="sortingArrow" style="margin-left:8px; width: 12px;"></span>
          <a-input-search
            placeholder="Search"
            style="width: 274px; margin-top:0px; font-weight: normal;"
            class="project-input-search"
            @change="onSearch"
          />
        </a-row></div>
        <a-row>
          <div class="contentarea" >
          <a-col v-if="!filterData" :span="19" class="empty-card-area">
            <img style="display: block; margin-left: auto; margin-right: auto; width: 293px; margin-top:54px" :src="require('../../assets/noProjectsImage.svg')">
            <div class="noProjectsTitle" >No workspaces to see yet.</div>
            <div class="noProjectsText" >Soon Dashmote will unlock your first workspace. <br>Contact your account manager for more info.</div>
          </a-col>
          <a-col v-else class="project-card-area" style="margin-right:8px;">
            <ShowProjectCard
              v-for="(key, item) in filterData"
              :key="item['name']"
              icon="grid-outline"
              :value="key[1]"
              :projectId="key[0]"
            />
          </a-col>
          <div class="rightcard" >
          <a-col class="project-card-slide">
      <ProjectSlideCard
        :category="projectslidecards[Object.keys(projectslidecards)[0]].category"
        :cardContent="projectslidecards[Object.keys(projectslidecards)[0]].title"
        :backgroundcolor="projectslidecards[Object.keys(projectslidecards)[0]].backgroundcolor"
        :iconbackgroundcolor="projectslidecards[Object.keys(projectslidecards)[0]].iconbackgroundcolor"
        :linkurl="projectslidecards[Object.keys(projectslidecards)[0]].linkurl"
        cardKey="1"
      />
      <ProjectSlideCard
        :category="projectslidecards[Object.keys(projectslidecards)[1]].category"
        :cardContent="projectslidecards[Object.keys(projectslidecards)[1]].title"
        :backgroundcolor="projectslidecards[Object.keys(projectslidecards)[1]].backgroundcolor"
        :iconbackgroundcolor="projectslidecards[Object.keys(projectslidecards)[1]].iconbackgroundcolor"
        :linkurl="projectslidecards[Object.keys(projectslidecards)[1]].linkurl"
        cardKey="2"
      />
      <ProjectSlideCard
        :category="projectslidecards[Object.keys(projectslidecards)[2]].category"
        :cardContent="projectslidecards[Object.keys(projectslidecards)[2]].title"
        :backgroundcolor="projectslidecards[Object.keys(projectslidecards)[2]].backgroundcolor"
        :iconbackgroundcolor="projectslidecards[Object.keys(projectslidecards)[2]].iconbackgroundcolor"
        :linkurl="projectslidecards[Object.keys(projectslidecards)[2]].linkurl"
        cardKey="3"
      />
          </a-col>
          </div>
          </div>
        </a-row>
      </a-col>
    </a-row>
  </div>
  <div v-else>
    <MobileProjects :data="cardData" :name="name" />
  </div>
</template>

<script>
import { auth, db } from '../../firebase'; // Import auth and db from firebase setup
import ShowProjectCard from './ShowProjectCard.vue';
import ProjectSlideCard from './ProjectSlideCard.vue';
import MobileProjects from './MobileProjects.vue';
import moment from 'moment';
import { path as RPath } from 'ramda';
const R = require('ramda');
const mobile = require('is-mobile');

const defaultState = {
  1: { iconname: 'cart', backgroundcolor: '#D3D6E3', iconbackgroundcolor: '#D3D6E3', title: '', linkurl: '' },
  2: { iconname: 'cart', backgroundcolor: '#D3D6E3', iconbackgroundcolor: '#D3D6E3', title: '', linkurl: '' },
  3: { iconname: 'cart', backgroundcolor: '#D3D6E3', iconbackgroundcolor: '#D3D6E3', title: '', linkurl: '' }
};

export default {
  name: 'Projects',
  components: {
    ShowProjectCard,
    ProjectSlideCard,
    MobileProjects
  },
  data() {
    return {
      mobile,
      overlay: false,
      projectslidecards: defaultState,
      cardData: {
        loadingProjectKey1: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } },
        loadingProjectKey2: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } },
        loadingProjectKey3: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } },
        loadingProjectKey4: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } },
        loadingProjectKey5: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } },
        loadingProjectKey6: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } }
      },
      search: '',
      proIdList: [],
      sortType: undefined,
      nameSortDirection: 'ascent',
      dateSortDirection: 'descent',
      user: null
    };
  },
  methods: {
    async getProjects() {
      try {
        const user = this.user || (await this.fetchCurrentUser());
        if (!user) throw new Error('No user found.');

        const getUserProjectsByEmail = auth.app.functions().httpsCallable('getUserProjectsByEmail');
        const response = await getUserProjectsByEmail(user.email);
        this.cardData = response.data;
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    },
    async fetchCurrentUser() {
      return new Promise((resolve) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            this.user = user;
            resolve(user);
          } else {
              auth.signOut();
              localStorage.removeItem('userEmail');
              localStorage.removeItem('accessToken');
              localStorage.removeItem('displayName');
              localStorage.removeItem('userProfileImageUrl');
              localStorage.removeItem('userTrackingProfileStatus');
              localStorage.removeItem('userReference');

              this.$intercom.shutdown(); // Shutdown Intercom session
              this.$intercom.boot();     // Restart Intercom

            console.error('No user is currently logged in.');
            this.$router.push('/login');
            resolve(null);
          }
        });
      });
    },
    async getCardsFromDB() {
      try {
        const snapshot = await db.ref('/projectslidecards').once('value');
        return snapshot.val();
      } catch (error) {
        console.error('Error fetching cards from database:', error);
        return defaultState;
      }
    },
    async setLastSeen() {
      try {
        const user = this.user || (await this.fetchCurrentUser());
        if (!user) return;

        const lastSeenData = { lastSeen: moment().format() };
        await db.ref(`users/${user.uid}`).update(lastSeenData);
      } catch (error) {
        console.error('Error updating last seen:', error);
      }
    },
    onSort(type) {
      if (type === 'name') {
        this.sortType = this.nameSortDirection === 'ascent' ? 'nameAsc' : 'nameDsc';
        this.nameSortDirection = this.nameSortDirection === 'ascent' ? 'descent' : 'ascent';
      } else if (type === 'date') {
        this.sortType = this.dateSortDirection === 'ascent' ? 'dateAsc' : 'dateDsc';
        this.dateSortDirection = this.dateSortDirection === 'ascent' ? 'descent' : 'ascent';
      }
    },
    onSearch(e) {
      this.search = e.target.value;
    },
    isContain(item) {
      if (this.search) {
        try {
          return item.name.toLowerCase().includes(this.search.toLowerCase());
        } catch (err) {
          console.error('Error filtering data:', err);
          return false;
        }
      }
      return true;
    },
    createTrackingProfile(email) {
      this.$mixpanel.identify(email);
      this.$mixpanel.people.set({ $email: email });
      localStorage.setItem('userTrackingProfileStatus', 'true');
      this.$store.commit('setUserTrackingProfileStatus', 'true');
    },
    timoutForNoData() {
      if (this.cardData === 'empty' || !Object.keys(this.cardData).length) {
        console.log('loading too long');
        this.cardData = 'empty';
      }
    }
  },
  computed: {
    filterData() {
      if (this.cardData === 'empty') return '';

      let filteredData = Object.entries(R.filter(this.isContain)(this.cardData));
      switch (this.sortType) {
        case 'nameAsc':
          filteredData.sort((a, b) => a[1].name.localeCompare(b[1].name));
          break;
        case 'nameDsc':
          filteredData.sort((a, b) => a[1].name.localeCompare(b[1].name)).reverse();
          break;
        case 'dateAsc':
          filteredData.sort((a, b) => new Date(a[1].createdAt) - new Date(b[1].createdAt));
          break;
        case 'dateDsc':
          filteredData.sort((a, b) => new Date(a[1].createdAt) - new Date(b[1].createdAt)).reverse();
          break;
      }
      return filteredData;
    }
  },
  mounted() {
    setTimeout(() => this.timoutForNoData(), 10000); // 10 seconds timeout
  },
  async created() {
    const user = await this.fetchCurrentUser();
    if (user) {
      this.getProjects();
      this.projectslidecards = await this.getCardsFromDB();
      this.setLastSeen();
      this.onSort('date');

      if (!localStorage.getItem('userTrackingProfileStatus')) {
        this.createTrackingProfile(user.email);
      }
    }
  }
};
</script>
<style scoped>
@import "./Projects.css";
</style>
